import * as Yup from 'yup';
const createValidationSchema = Yup.object({
  title: Yup.string()
    .max(100, 'form_validation_message.title_length_validation')
    .required('form_validation_message.title_required'),
  description: Yup.string()
    .max(250, 'form_validation_message.description_length_validation').nullable(),
    // .required('form_validation_message.description_required'),
  calendar_preset_type: Yup.object().required(
    'form_validation_message.calendar_preset_type_required'
  ),
  members: Yup.array()
    .of(Yup.string().email('form_validation_message.group_schedule_invalid_email').required('form_validation_message.group_schedule_email_required'))
    .min(0, 'form_validation_message.at_least_one_email_required'),
  calendar_preset: Yup.array().when('calendar_preset_type', {
    is: (val) => val && val.value === '1',
    then: () => Yup.array().required('form_validation_message.calendar_preset_required'),
    otherwise: () => Yup.array().notRequired(),
  }),
  start_time: Yup.string().when('calendar_preset_type', {
    is: (val) => val && val.value === '1',
    then: () => Yup.string().required('group_schedule.start_time_required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  end_time: Yup.string().when('calendar_preset_type', {
    is: (val) => val && val.value === '1',
    then: () =>
      Yup.string()
        .required('group_schedule.end_time_required')
        .test(
          'is-greater',
          'form_validation_message.end_time_greater_than_start_time',
          function (value) {
            const { start_time } = this.parent;
            return !start_time || !value || start_time < value;
          }
        ),
    otherwise: () => Yup.string().notRequired(),
  }),
  color :Yup.string().notRequired(),
  // color: Yup.string()
  //   .oneOf(
  //     colors.map((c) => c.value),
  //     'form_validation_message.invalid_color'
  //   )
  //   .required('form_validation_message.color_required'),
});

const editValidationSchema = Yup.object({
  title: Yup.string()
    .max(100, 'form_validation_message.title_length_validation')
    .required('form_validation_message.title_required'),
  // description: Yup.string()
  //   .max(250, 'form_validation_message.description_length_validation')
  //   .required('form_validation_message.description_required'),
  calendar_preset_type: Yup.object().notRequired(),
  members: Yup.array()
    .of(Yup.string().email('Invalid email format').required('Email is required'))
    .min(0, 'At least one email is required'),
  calendar_preset: Yup.object().notRequired(),
  start_time: Yup.object().notRequired(),
  end_time: Yup.object().notRequired(),
  color : Yup.string().required('form_validation_message.color_required'),
});

const getValidationSchema = (isEdit) => {
  return isEdit ? editValidationSchema : createValidationSchema;
};

export default getValidationSchema
