import { useMutation, useQuery } from 'react-query';
import { CalendarService } from '../api';
import { toast } from 'react-toastify';
/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAuthenticateUserCalendarCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.authenticateUserCalendarCode, {
    onSuccess,
    onError,
  });
};
const useGetCalendarAccount = (account, onSuccess, onError = onDefaultError) => {
  return useQuery([`account`, [account]], CalendarService.getCalendarAccount, {
    onSuccess,
    onError,
  });
};

const useTrashCalendarAccount = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.trashCalendarAccount, {
    onSuccess,
    onError,
  });
};
const useGetCalendarEventList = (event, onSuccess, onError = onDefaultError) => {
  return useQuery([`event`, event], () => CalendarService.getCalendarEventList(event), {
    onSuccess,
    onError,
  });
};
const useAuthorizeOutloolCalendarAccount = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.authorizeOutloolCalendarAccount, {
    onSuccess,
    onError,
  });
};

const useCreateSyncoEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.createSyncoEvent, {
    onSuccess,
    onError,
  });
};
const useDeleteSyncoEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.deleteSyncoEvent, {
    onSuccess,
    onError,
  });
};
const useEditSyncoEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.editSyncoEvent, {
    onSuccess,
    onError,
  });
};
const useUpdateSyncoEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.updateSyncoEvent, {
    onSuccess,
    onError,
  });
};
const useDeleteEventSeries = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.deleteEventSeries, {
    onSuccess,
    onError,
  });
};
const useCreateAppleCalendarAccount = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.createAppleCalendarAccount, {
    onSuccess,
    onError,
  });
};
const useSyncAppleCalendar = (onSuccess, onError = onDefaultError) => {
  return useQuery([`apple-calendar-sync`, []], () => CalendarService.syncAppleCalendar(), {
    onSuccess,
    onError,
  });
};


// group schedule route
const useGetMemberList = (onSuccess, onError = onDefaultError) => {
  return useQuery([`get-member`, []], () => CalendarService.getMemberList(), {
    onSuccess,
    onError,
  });
};
const useCreateGroupSchedule = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.createGroupSchedule, {
    onSuccess,
    onError,
  });
};
const useGetNotificationList = (onSuccess, onError = onDefaultError) => {
  return useQuery([`get-notification`, []], () => CalendarService.getNotificationList(), {
    onSuccess,
    onError,
  });
};

const useDeclineNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.declineNotification, {
    onSuccess,
    onError,
  });
};
const useAcceptNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.acceptNotification, {
    onSuccess,
    onError,
  });
};

const useDeleteNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.deleteNotification, {
    onSuccess,
    onError,
  });
};

const useCalendarsWithActiveMembers = (onSuccess, onError = onDefaultError) => {
  return useQuery([`get-group-schedule`, []], () => CalendarService.getCalendarsWithActiveMembers(), {
    onSuccess,
    onError,
  });
};

const useLeaveGroup = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.leaveGroup, {
    onSuccess,
    onError,
  });
};

const useGetGroupScheduleDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.getGroupScheduleDetails, {
    onSuccess,
    onError,
  });
};
const useUpdateGroupSchedule = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.updateGroupSchedule, {
    onSuccess,
    onError,
  });
};
const useGetUnreadNotificationsCount = (onSuccess, onError = onDefaultError) => {
  return useQuery([`get-unread-notification-count`, []], () => CalendarService.getUnreadNotificationsCount(), {
    onSuccess,
    onError,
  });
};
const useGetMarkAsReadNotifications = (onSuccess, onError = onDefaultError) => {
  return useQuery([`mark-as-read-notification-count`, []], () => CalendarService.getMarkAsReadNotifications(), {
    onSuccess,
    onError,
  });
};

const useGetGroupCalender = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.getGroupCalender, {
    onSuccess,
    onError,
  });
};
const useCreateGroupScheduleEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.createGroupScheduleEvent, {
    onSuccess,
    onError,
  });
};
const useUpdateGroupScheduleEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.updateGroupScheduleEvent, {
    onSuccess,
    onError,
  });
};
const useGetGroupCalendarEventList = (event, onSuccess, onError = onDefaultError) => {
  return useQuery([`event`, event], () => CalendarService.getGroupCalendarEventList(event), {
    onSuccess,
    onError,
  });
};
const useAcceptGroupEventInvitation = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.acceptGroupEventInvitation, {
    onSuccess,
    onError,
  });
};
const useRejectGroupEventInvitation = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.rejectGroupEventInvitation, {
    onSuccess,
    onError,
  });
};
const useEditGroupEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.editGroupEvent, {
    onSuccess,
    onError,
  });
};
const useDeleteGroupEvent = (onSuccess, onError = onDefaultError) => {
  return useMutation(CalendarService.deleteGroupEvent, {
    onSuccess,
    onError,
  });
};
const useGetGroupCalendarAccount = (account, onSuccess, onError = onDefaultError) => {
  return useQuery([`account`, [account]], CalendarService.getGroupCalendarAccount, {
    onSuccess,
    onError,
  });
};
export {
  useAuthenticateUserCalendarCode,
  useGetCalendarAccount,
  useTrashCalendarAccount,
  useGetCalendarEventList,
  useAuthorizeOutloolCalendarAccount,
  useCreateSyncoEvent,
  useDeleteSyncoEvent,
  useEditSyncoEvent,
  useUpdateSyncoEvent,
  useDeleteEventSeries,
  useCreateAppleCalendarAccount,
  useSyncAppleCalendar,
  useGetMemberList,
  useCreateGroupSchedule,
  useGetNotificationList,
  useDeclineNotification,
  useAcceptNotification,
  useCalendarsWithActiveMembers,
  useLeaveGroup,
  useGetGroupScheduleDetails,
  useUpdateGroupSchedule,
  useGetUnreadNotificationsCount,
  useGetMarkAsReadNotifications,
  useGetGroupCalender,
  useCreateGroupScheduleEvent,
  useUpdateGroupScheduleEvent,
  useGetGroupCalendarEventList,
  useAcceptGroupEventInvitation,
  useRejectGroupEventInvitation,
  useEditGroupEvent,
  useDeleteGroupEvent,
  useGetGroupCalendarAccount,
  useDeleteNotification
};
