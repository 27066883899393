export const API_URL = 'https://api.synco.social/api/v1/';
export const WEB_URL = 'https://synco.social/';

// cms page slugs
export const PRIVACY_POLICY_SLUG = 'privacy-policy';
export const TERM_CONDITION_SLUG = 'term-condition';
export const ABOUT_US_SLUG = 'about-us';
export const INFORMATION_SLUG = 'information';
export const PWA_CACHE_VERSION = '1.35';

export const CALENDAR_AUTH_URL = 'https://www.googleapis.com/auth/calendar';
export const USERINFO_AUTH_URL = 'https://www.googleapis.com/auth/userinfo.profile';
export const CALENDAR_CLIENT_ID = '918103553376-kcp745faag6m2iavnsl7e9g860gujjh5.apps.googleusercontent.com';
export const CALENDAR_REDIRECT_URL = 'https://synco.social';
export const GOOGLE_CLIENT_SECRET="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"


export const EventColors = [
    { value: '#F7CE46' },
    { value: '#EF4444' },
    { value: '#FF8328' },
    { value: '#10B981' },
    { value: '#48A7FF' },
    { value: '#6F00FD' },
  ];

export const ShareScheduleType = [
  { value: '1', label: 'Show All' },
  { value: '2', label: 'Show Title' },
  { value: '3', label: 'Show Private' },
];

// Preset Type
export const presetType = [
  { value: '2', label: 'Weekend' },
  { value: '3', label: 'Week Day' },
  { value: '4', label: 'Full Week' },
  { value: '5', label: 'Weeknights' },
  { value: '1', label: 'Custom' },
];

// Preset selection for custom Type
export const presetCustomType = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '0', label: 'Sunday' },
];
