import React from 'react';
import PropTypes from 'prop-types';
const ToggleButton = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill={svgColor}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00006 18.0008H21.0001V16.0006H3.00006V18.0008ZM3.00006 13.0006H21.0001V11.0009H3.00006V13.0006ZM3.00006 6.00076V8.00092H21.0001V6.00076H3.00006Z"
        fill="white"
      />
    </svg>
  );
};
ToggleButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default ToggleButton;
