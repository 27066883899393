import React, { useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { presetType, presetCustomType } from 'config';
import PropTypes from 'prop-types';
import CrossCancleIcon from 'common/icons/CrossCancleIcon';
import ArrowForwardIcon from 'common/icons/ArrowForwardIcon';
import moment from 'moment';
import { MultiMailInput } from './MultiMailInput';
import { Link } from 'react-router-dom';

const FormStep1 = ({
  t,
  formik,
  handleClear,
  nextStep,
  handleCloseModel,
  editGroupSchedule,
  memberList,
  isCustomType,
  setIsCustomType,
  handleClearModel,
  isMobile,
}) => {
  const [isSelectCustomType, setIsSelectCustomType] = useState(false);
  // const defaultPresetType=presetType.find((i)=>i.value==4);

   // Function to remove overflow:auto from the wrapper
   const removeOverflow = () => {
    const element = document.querySelector('.sync-model-popup-content-wrapper');
    if (element) {
      element.style.overflow = 'unset';
    }
  };

   // Function to restore overflow:auto to the wrapper (if needed)
   const restoreOverflow = () => {
    const element = document.querySelector('.sync-model-popup-content-wrapper');
    if (element) {
      element.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    // Restore overflow when component unmounts
    return () => restoreOverflow();
  }, []);

  return (
    <>
      <div className="group-schedule-step">
        <span>1</span>
        {t('group_schedule.group_schedule_step_1')}
      </div>
      <Row>
        <Col xs="12" lg="12" className="mb-2">
          <Form.Group className="form-group title-field-input">
            <Form.Label>{t('group_schedule.form_title_label')}</Form.Label>
            <Form.Control
              className={
                'form-field ' +
                (formik.touched.title && formik.errors.title
                  ? 'is-invalid'
                  : formik.touched.title && !formik.errors.title
                  ? 'form-field-success'
                  : '')
              }
              type="text"
              name="title"
              placeholder={t('group_schedule.form_title_placeholder')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              autoComplete="off"
              maxLength={150}
            />

            {formik.touched.title && formik.errors.title ? (
              <div className="clear-btn-wrapper">
                <span className="input-group-text is-invalid " onClick={() => handleClear('title')}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
            ) : null}
            <div className="invalid-feedback">
              {formik.touched.title && formik.errors.title ? (
                <span>{t(formik.errors.title)}</span>
              ) : null}
            </div>
          </Form.Group>
        </Col>
        <Col
          xs={editGroupSchedule ? '12' : '12'}
          lg={editGroupSchedule ? '6' : '12'}
          className="mb-2">
          <Form.Group className="form-group mt-3 description-div-wrapper">
            <Form.Label>{t('group_schedule.form_description_label')}</Form.Label>
            <Form.Control
              className={
                'form-field ' +
                (formik.touched.description && formik.errors.description
                  ? 'is-invalid'
                  : formik.touched.description && !formik.errors.description
                  ? 'form-field-success'
                  : '')
              }
              as="textarea"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              autoComplete="off"
              placeholder={t('group_schedule.form_description_placeholder')}
            />

            {formik.touched.description && formik.errors.description ? (
              <div className="clear-btn-wrapper">
                <span
                  className="input-group-text is-invalid "
                  onClick={() => handleClear('description')}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
            ) : null}
            <div className="invalid-feedback">
              {formik.touched.description && formik.errors.description ? (
                <span>{t(formik.errors.description)}</span>
              ) : null}
            </div>
          </Form.Group>
        </Col>
        {editGroupSchedule && (
          <Col
            xs={editGroupSchedule ? '12' : '12'}
            lg={editGroupSchedule ? '6' : '12'}
            className="mb-2">
            <Form.Group className="form-group mt-3">
              <Form.Label>{t('group_schedule.add_friends_label')}</Form.Label>
              <MultiMailInput
                className={
                  'form-field ' +
                  (formik.touched.members && formik.errors.members
                    ? 'is-invalid'
                    : formik.touched.members && !formik.errors.members
                    ? 'form-field-success'
                    : '')
                }
                name="members"
                error={formik.errors.members}
                touched={formik.touched.members}
                formik={formik}
                t={t}
              />
            </Form.Group>
          </Col>
        )}
      </Row>

      <Row>
        {!editGroupSchedule && (
          <>
            <Col xs="12" lg="6">
              <Form.Group className="form-group mt-3">
                <Form.Label>{t('group_schedule.calendar_preset_title')}</Form.Label>
                <Select
                  className={
                    'form-field cal-preset-select' +
                    (formik.touched.calendar_preset_type && formik.errors.calendar_preset_type
                      ? 'is-invalid'
                      : formik.touched.calendar_preset_type && !formik.errors.calendar_preset_type
                      ? 'form-field-success'
                      : '')
                  }
                  name="calendar_preset_type"
                  options={presetType}
                  onChange={(option) => {
                    setIsSelectCustomType(true);
                    formik.setFieldValue('calendar_preset_type', option);
                    if (option.value === '1') {
                      setIsCustomType(true);
                      formik.setFieldValue('start_time', moment().format('HH:mm'));
                      formik.setFieldValue('end_time', moment().add(3, 'hour').format('HH:mm'));
                    } else {
                      setIsCustomType(false);
                      formik.setFieldValue('start_time', '');
                      formik.setFieldValue('end_time', '');
                    }
                  }}
                  onBlur={() => {formik.setFieldTouched('calendar_preset_type', true);restoreOverflow();}}
                  value={formik.values.calendar_preset_type}
                  placeholder={t('group_schedule.calendar_preset_type_placeholder')}
                  onFocus={removeOverflow} // Remove overflow on focus
                 
                />
                <div className="invalid-feedback">
                  {formik.touched.calendar_preset_type && !isSelectCustomType && formik.errors.calendar_preset_type ? (
                    <span>{t(formik.errors.calendar_preset_type)}</span>
                  ) : null}
                </div>
              </Form.Group>
              {isCustomType && (
                <>
                  {/* <div className="text-center mt-3">
                     <Form.Label>or</Form.Label> 
                  </div> */}
                  <Form.Group className="form-group mt-4">
                    <Select
                      isMulti
                      className={
                        'form-field cal-preset-select' +
                        (formik.touched.calendar_preset && formik.errors.calendar_preset
                          ? 'is-invalid'
                          : formik.touched.calendar_preset && !formik.errors.calendar_preset
                          ? 'form-field-success'
                          : '')
                      }
                      name="calendar_preset"
                      options={presetCustomType}
                      onChange={(option) => {
                        formik.setFieldValue('calendar_preset', option.length ? option : '')
                      }}
                      onBlur={() => {formik.setFieldTouched('calendar_preset', true);restoreOverflow();}}
                      value={formik.values.calendar_preset}
                      placeholder={t('group_schedule.calendar_preset_placeholder')}
                      onFocus={removeOverflow} // Remove overflow on focus
                    />
                    <div className="invalid-feedback">
                      {formik.touched.calendar_preset && formik.errors.calendar_preset ? (
                        <span>{t(formik.errors.calendar_preset)}</span>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Row className="start-end-time-wrapper mt-3">
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label style={{ color: '#666666' }}>
                          {t('group_schedule.start_time_label')}
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field ' +
                            (formik.touched.start_time && formik.errors.start_time
                              ? 'is-invalid'
                              : formik.touched.start_time && !formik.errors.start_time
                              ? 'form-field-success'
                              : '')
                          }
                          type="time"
                          name="start_time"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.start_time}
                          step={900}
                        />
                        {formik.touched.start_time && formik.errors.start_time ? (
                          <div className="clear-btn-wrapper">
                            <span
                              className="input-group-text is-invalid"
                              onClick={() => handleClear('start_time')}>
                              <FontAwesomeIcon icon={faXmark} />
                            </span>
                          </div>
                        ) : null}
                        <div className="invalid-feedback errorMsg">
                          {formik.touched.start_time && formik.errors.start_time ? (
                            <span>{t(formik.errors.start_time)}</span>
                          ) : null}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label>{t('group_schedule.end_time_label')}</Form.Label>
                        <Form.Control
                          type="time"
                          name="end_time"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.end_time}
                          step={900}
                          className={
                            'form-field ' +
                            (formik.touched.end_time && formik.errors.end_time
                              ? 'is-invalid'
                              : formik.touched.end_time && !formik.errors.end_time
                              ? 'form-field-success'
                              : '')
                          }
                        />
                        {formik.touched.end_time && formik.errors.end_time ? (
                          <div className="clear-btn-wrapper">
                            <span
                              className="input-group-text is-invalid"
                              onClick={() => handleClear('end_time')}>
                              <FontAwesomeIcon icon={faXmark} />
                            </span>
                          </div>
                        ) : null}
                        <div className="invalid-feedback errorMsg">
                          {formik.touched.end_time && formik.errors.end_time ? (
                            <span>{t(formik.errors.end_time)}</span>
                          ) : null}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <Col xs="12" lg="6">
              <Form.Group className="form-group mt-3">
                <Form.Label>{t('group_schedule.add_friends_label')}</Form.Label>
                <MultiMailInput
                  className={
                    'form-field ' +
                    (formik.touched.members && formik.errors.members
                      ? 'is-invalid'
                      : formik.touched.members && !formik.errors.members
                      ? 'form-field-success'
                      : '')
                  }
                  name="members"
                  error={formik.errors.members}
                  touched={formik.touched.members}
                  formik={formik}
                  t={t}
                />
              </Form.Group>
            </Col>
          </>
        )}
        {!editGroupSchedule && (
          <Col xs="12" lg="12">
            <Row className="btn-wrapper mt-4">
              <Col xs="6" lg="6">
                {isMobile ? (
                  <>
                    <Link
                      to={'/group-schedules'}
                      className="btn btn-primary btn-primary-without-bg cancel-btn">
                      <CrossCancleIcon width={12} height={12}></CrossCancleIcon>
                      {t('group_schedule.cancel_btn_label')}
                    </Link>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={handleClearModel}
                      className="btn btn-primary btn-primary-without-bg cancel-btn">
                      <CrossCancleIcon width={12} height={12}></CrossCancleIcon>
                      {t('group_schedule.cancel_btn_label')}
                    </Button>
                  </>
                )}
              </Col>
              <Col xs="6" lg="6">
                <Button
                  className="btn btn-primary btn-primary-without-bg next-btn"
                  onClick={nextStep}>
                  {t('group_schedule.next_btn_label')}
                  <ArrowForwardIcon></ArrowForwardIcon>
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};
FormStep1.propTypes = {
  t: PropTypes.any,
  formik: PropTypes.any,
  handleClear: PropTypes.any,
  nextStep: PropTypes.any,
  handleCloseModel: PropTypes.any,
  editGroupSchedule: PropTypes.any,
  handleClearModel: PropTypes.any,
  isMobile: PropTypes.any,
};
export { FormStep1 };
