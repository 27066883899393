import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthFooter } from './Footer/AuthFooter';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import 'assets/scss/style.scss';
import 'assets/scss/page/_auth.scss';

const AuthLayout = (props) => {
  const { t } = useTranslation();
  const showFooter = props.showFooter;
  // Adding class in body
  useEffect(() => {
    // document.body.classList.add('bg-box');
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = 'bg-box';
  }, []);
  return (
    <div
      {...props}
      className="d-flex align-items-center justify-content-center h-100vh p-3 psm-0 page-main-wrapper">
      <Container>
        <Row className="justify-content-md-center page-form-wrapper align-items-center">
          {props.children}
          {showFooter && <AuthFooter t={t} />}
        </Row>
      </Container>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
  showFooter: PropTypes.bool.isRequired,
};

export { AuthLayout };
