import React from 'react';
import PropTypes from 'prop-types';
const CheckMarkIcon = ({ width, height }) => {
  const svgWidth = width ? `${width}px` : '23px';
  const svgHeight = height ? `${height}px` : '20px';
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4999 2.00061L7.49989 18.0006L1.49989 12.0006"
        stroke="#28BA62"
        strokeWidth="2.0625"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};
CheckMarkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default CheckMarkIcon;
