import { useSearchParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

const CallBackAction = () => {
  const [searchParams] = useSearchParams();
  const initialized = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const mode = searchParams.get('mode');
      const oobCode = searchParams.get('oobCode');
      const continueUrl = searchParams.get('continueUrl');
      const url = new URL(continueUrl);
      const pathname = url.pathname;
      const search = url.search;
      const redirectUrl = `${pathname}${search}&mode=${mode}&oobCode=${oobCode}`;
      navigate(redirectUrl);
    }
  });
  return <></>;
};

export default CallBackAction;
