import React, { useState } from 'react';
import CrossIcon from 'common/icons/CrossIcon';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import CheckMarkIcon from 'common/icons/CheckMarkIcon';

const MultiMailInput = ({
  error,
  touched,
  formik,
  t,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDropdown, setShowDropdown] = useState(false); // Add state for dropdown visibility

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      return `This email is not valid!`;
    }
    if (formik.values.members.includes(email)) {
      return `This email is already exist!`;
    }
    return null;
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === 'Tab') {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue.length > 0) {
        const errorMsg = validateEmail(trimmedValue);
        setErrorMessage(errorMsg);
        if (!errorMsg && trimmedValue.length > 0) {
          setShowDropdown(true); // Show the dropdown if email is valid
        } else {
          setShowDropdown(false); // Hide dropdown on error
          formik.setErrors({ members: errorMsg });
        }
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const trimmedValue = e.target.value.trim();
    const errorMsg = validateEmail(trimmedValue);
    if (!errorMsg && trimmedValue.length > 0) {
      setShowDropdown(true); // Show dropdown if no error
    } else {
      setShowDropdown(false); // Hide dropdown on error
    }
  };

  const handleAddEmail = async (email) => {
    const newEmails = [...formik.values.members, email];
    await formik.setFieldValue('members', newEmails);
    setInputValue('');
    setShowDropdown(false); // Hide dropdown after adding email
  };

  const handleDelete = (emailToDelete) => {
    const updatedEmails = formik.values.members.filter((email) => email !== emailToDelete);
    formik.setFieldValue('members', updatedEmails);
    toast.success('Email removed successfully!');
  };

  return (
    <div className={`multi-mail-input ${touched && error ? 'is-invalid' : ''} ${errorMessage ? 'is-invalid' : ''}`}>
      <input
        className={`add-member-input form-field form-control ${touched && error ? 'is-invalid' : ''} ${formik.values.members?.length > 0 ? 'email' : ''
          }`}
        type="email"
        name="members"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={t('group_schedule.form_add_friend_placeholder')}
        onBlur={() => formik.setFieldTouched('members', true)}
      />
      {showDropdown && (
      <button
        type="button"
        className="btn btn-primary add_email_btn"
        onClick={() => handleAddEmail(inputValue)}
      >
        <CheckMarkIcon width={20} height={17}></CheckMarkIcon>
      </button>
      )}

      {/* Show dropdown for email suggestion */}
      {showDropdown && (
        <div className="email-dropdown">
          <div
            className="dropdown-item"
            onClick={() => handleAddEmail(inputValue)} // Add email on click
          >
            {inputValue}
          </div>
        </div>
      )}

      <div className={`emails-list ${formik.values.members?.length > 0 ? 'email-list-fill' : ''
        }`}>
        {formik.values.members.map((email) => (
          <div key={email} className="email-item">
            {email}
            <button type="button" onClick={() => handleDelete(email)} className="delete-button">
              <CrossIcon width={12} height={12} />
            </button>
          </div>
        ))}
      </div>
      <div className="invalid-feedback">
        {errorMessage ||
          (formik.touched.members && formik.errors.members && (
            <span>{t(formik.errors.members) ?? errorMessage}</span>
          ))}
      </div>
    </div>
  );
};

MultiMailInput.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  formik: PropTypes.any,
  t: PropTypes.any,
};

export { MultiMailInput };
