import React from 'react';
import PropTypes from 'prop-types';
const Checkmark = ({ width, height }) => {
  const svgWidth = width ? `${width}px` : '20px';
  const svgHeight = height ? `${height}px` : '20px';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9375 5.25037L7.625 14.7504L4.0625 11.1879"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Checkmark.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default Checkmark;
