// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyBqtmtSl8gjIye-UEaClMLPV7oRR22zZW0",
    authDomain: "synco-social.firebaseapp.com",
    projectId: "synco-social",
    storageBucket: "synco-social.appspot.com",
    messagingSenderId: "918103553376",
    appId: "1:918103553376:web:7e160eb57f0cbedb155a05",
    measurementId: "G-3FYK90JZ7M"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
