import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Loader from 'common/components/Loader';
import { persistor, store } from '../store';
import { QueryClientProvider, QueryClient } from 'react-query';
import PagesRoutes from 'routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthToken } from 'libs/HttpClient';
import { FirebaseAuthContextProvider } from 'context/FirebaseAuthContext';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config/outlookConfig';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const { t } = useTranslation();

  const handleOnBeforeLift = () => {
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
  };
  const outlookInstance = new PublicClientApplication(msalConfig);

  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor} onBeforeLift={handleOnBeforeLift}>
            <QueryClientProvider client={queryClient}>
              <FirebaseAuthContextProvider t={t}>
              <MsalProvider instance={outlookInstance}>
                <ToastContainer />
                <PagesRoutes t={t} />
                </MsalProvider>
              </FirebaseAuthContextProvider>
            </QueryClientProvider>
          </PersistGate>
        
      </Provider>
    </Suspense>
  );
}

export default App;
