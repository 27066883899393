import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import HomeIcon from 'common/icons/HomeIcon';
import MyProfile from 'common/icons/MyProfile';
import MenuClose from 'common/icons/MenuClose';
import CalendarIcon from 'common/icons/CalendarIcon';
import GroupScheduleMenuIcon from 'common/icons/GroupScheduleMenuIcon';
import NotificationMenuIcon from 'common/icons/NotificationMenuIcon';
import { useSelector } from 'react-redux';
import { loggedUser } from 'store/features/authSlice';
const Sidebar = ({ t, isActive, setIsActive, brandLogo }) => {
  const handleCloseMenu = () => {
    setIsActive(false);
  };
  const getUserData = useSelector(loggedUser);
  return (
    <>
      <nav id="sidebar" className={isActive ? 'active' : 'inactive'}>
        <div className="mobile--menu-logo-wrapper">
          <Link to={'/dashboard'} onClick={handleCloseMenu}>
            <img
              src={brandLogo}
              height="40"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Link>
          <Button className="menu-close-btn" onClick={handleCloseMenu}>
            <MenuClose />
          </Button>
        </div>
        <ul className="list-unstyled components">
          <li>
            <NavLink to="/dashboard" onClick={handleCloseMenu} className="stroke">
              <HomeIcon></HomeIcon>
              {t('page.dashboard_label')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/edit-profile" onClick={handleCloseMenu} className="stroke">
              <MyProfile width={24} height={24} color={'fff'}></MyProfile>
              {t('page.header_editprofile')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/sync-schedules" onClick={handleCloseMenu} className="stroke">
              <CalendarIcon width={24} height={24} color={'fff'}></CalendarIcon>
              {t('calendar.my_schedules')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/notification" onClick={handleCloseMenu} className="notification-icon">
              {getUserData.unread_notification_count > 0 && (
                <span className="notification-count">{getUserData.unread_notification_count}</span>
              )}
              <NotificationMenuIcon width={30} height={30} color={'fff'}></NotificationMenuIcon>
              {t('page.header_notification')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/group-schedules" onClick={handleCloseMenu} className={'fill'}>
              <GroupScheduleMenuIcon width={30} height={30} color={'fff'} ></GroupScheduleMenuIcon>
              {t('calendar.group_schedules_menu_label')}
            </NavLink>
          </li>

          <div className='gs-scrl-div'>
          {getUserData.calender_account && getUserData.calender_account.length > 0 && (
            getUserData.calender_account.map((account, index) => (
              <li key={index}>
                <NavLink onClick={handleCloseMenu}  to={`/group-calender/${account.group_schedule_id}`} className="fill group-schedule-name" data-toggle="tooltip" data-placement="right" title={account.title}>
                  {account.title}
                </NavLink>
              </li>
            ))
          )}
          </div>
        </ul>
      </nav>
    </>
  );
};
Sidebar.propTypes = {
  t: PropTypes.any,
  isActive: PropTypes.any,
  setIsActive: PropTypes.any,
  brandLogo: PropTypes.any,
};

export { Sidebar };
