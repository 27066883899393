import { useContext, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { auth } from '../config/firebaseConfig';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  getAuth,
  updateProfile,
  updatePassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  checkActionCode,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import { WEB_URL } from 'config';

const FirebaseAuthContext = createContext();

const FirebaseAuthContextProvider = ({ children, t }) => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  });

  // Google sign in with popup
  const googleSignIn = async () => {
    let provider = new GoogleAuthProvider();
    const response = await signInWithPopup(auth, provider)
      .then((res) => {
        setCurrentUser(res.user);
        return res; // return the response
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };
  const userSignIn = async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password).then(
      (res) => {
        setCurrentUser(res.user);
        return res;
      },
      (error) => {
        handleAuthError(error);
      }
    );
    return response;
  };
  const userSignUp = async (email, password) => {
    const response = await createUserWithEmailAndPassword(auth, email, password).then(
      (res) => {
        setCurrentUser(res.user);
        return res;
      },
      (error) => {
        handleAuthError(error);
      }
    );
    return response;
  };

  // send signup email verification email
  const sendSignupEmailVerification = async (email,user) => {
    // Add action code for handle call back url from firebase
    const actionCodeSettings = {
      url: WEB_URL + 'sign-up?email=' + email + '',
      handleCodeInApp: true,
    };
    const response = sendEmailVerification(user, actionCodeSettings)
      .then(function (res) {
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // user sign out
  const userSignOut = async () => {
    const userAuth = getAuth();
    const response = await signOut(userAuth)
      .then((res) => {
        setCurrentUser(null);
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // update user profile
  const updateUserProfile = async (data) => {
    const response = await updateProfile(auth.currentUser, data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // update user password
  const updateUserPassword = async (password) => {
    const response = await updatePassword(auth.currentUser, password)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // send reset password email
  const sendResetPasswordEmail = async (email) => {
    const actionCodeSettings = {
      url: WEB_URL + 'forgot-password?email=' + email + '',
      handleCodeInApp: true,
    };
    const response = await sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // send reset password email
  const resetUserPassword = async (oobCode, password) => {
    const response = await confirmPasswordReset(auth, oobCode, password)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // check action code from verification email

  const checkUserActionCode = async (oobCode) => {
    const response = await checkActionCode(auth, oobCode)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        handleAuthError(error);
      });
    return response;
  };

  // handle firebase auth errors
  const handleAuthError = (error) => {
    const errorCode = error.code;
    let errorMessageToShow = null;
    switch (errorCode) {
      case 'auth/invalid-credential':
        errorMessageToShow = t('firebase_error.invalid_credential');
        break;
      case 'auth/operation-not-allowed':
        errorMessageToShow = t('firebase_error.signin_operation_not_allowed');
        break;
      case 'auth/too-many-requests':
        errorMessageToShow = t('firebase_error.too_many_requests');
        break;
      case 'auth/expired-action-code':
        errorMessageToShow = t('firebase_error.expired_action_code');
        break;
      case 'auth/invalid-action-code':
        errorMessageToShow = t('firebase_error.invalid_action_code');
        break;
      case 'auth/user-disabled':
        errorMessageToShow = t('firebase_error.user_disabled');
        break;
      case 'auth/user-not-found':
        errorMessageToShow = t('firebase_error.user_not_found');
        break;
      case 'auth/email-already-in-use':
        errorMessageToShow = t('firebase_error.email_already_in_use');
        break;
      case 'auth/invalid-email':
        errorMessageToShow = t('firebase_error.invalid_email');
        break;
      case 'auth/weak-password':
        errorMessageToShow = t('firebase_error.weak_password');
        break;
      case 'auth/user-token-expired':
        errorMessageToShow = t('firebase_error.signin_operation_not_allowed');
        break;
      default:
        //errorMessageToShow = t('firebase_error.default_error_message');
        break;
    }
    if(errorMessageToShow){
      toast.error(errorMessageToShow);
    }
    
  };

  return (
    <FirebaseAuthContext.Provider
      value={{
        currentUser,
        googleSignIn,
        userSignIn,
        userSignUp,
        sendSignupEmailVerification,
        userSignOut,
        updateUserProfile,
        updateUserPassword,
        resetUserPassword,
        checkUserActionCode,
        sendResetPasswordEmail
      }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};
FirebaseAuthContextProvider.propTypes = {
  children: PropTypes.any,
  t: PropTypes.any,
};
const FirebaseAuth = () => {
  return useContext(FirebaseAuthContext);
};
export { FirebaseAuthContextProvider, FirebaseAuth };
