import React from 'react';
import PropTypes from 'prop-types';
const CrossIcon = ({ width, height }) => {
  const svgWidth = width ? `${width}px` : '19px';
  const svgHeight = height ? `${height}px` : '18px';
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 2.09374L16.4069 0.000610352L9.50001 6.90748L2.59314 0.000610352L0.500011 2.09374L7.40689 9.00061L0.500011 15.9075L2.59314 18.0006L9.50001 11.0937L16.4069 18.0006L18.5 15.9075L11.5931 9.00061L18.5 2.09374Z"
        fill="#666666"
      />
    </svg>
  );
};
CrossIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default CrossIcon;
