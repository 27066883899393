import React from 'react';
const ArrowForwardIcon = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.94543 4.65784L15.2892 10.0016L9.94543 15.3453"
        stroke="white"
        strokeWidth="1.77497"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.547 9.99698H3.71106"
        stroke="white"
        strokeWidth="1.77497"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
ArrowForwardIcon.propTypes = {
  
};
export default ArrowForwardIcon;
