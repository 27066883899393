import { useQuery } from 'react-query';
import { CmsService } from '../api';
import { toast } from 'react-toastify';
/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useCmsContent = (slug, onSuccess, onError = onDefaultError) => {
  return useQuery([`slug`, [slug]], () => CmsService.getCmsContent(slug), {
    onSuccess,
    onError,
  });
};

export { useCmsContent };
