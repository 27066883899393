import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { settingData } from 'store/features/settingSlice';
import { loggedUser, logoutSuccess } from 'store/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'assets/images/logo-light.png';
import { NavDropdown } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import ToggleButton from 'common/icons/ToggleButton';
import { Sidebar } from '../Sidebar/Sidebar';
import 'assets/scss/style.scss';
import { FirebaseAuth } from 'context/FirebaseAuthContext';
import MobileNotificationIcon from 'common/icons/MobileNotificationIcon';

const Header = ({ t }) => {
  // const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getSettingData = useSelector(settingData);
  const getUserData = useSelector(loggedUser);
  const brandLogo = getSettingData.login_meta_brand_logo_light ?? Logo;
  const [isActive, setIsActive] = useState(false);
  const { userSignOut } = FirebaseAuth();

  const handleLogout = () => {
    userSignOut().then((res) => {
      dispatch(logoutSuccess());
      navigate('/login');
    });
  };
  const handleOpenMenu = () => {
    setIsActive(true);
  };

  return (
    <>
      <Navbar fixed="top" className="bg-body-tertiary bg-primary">
        <Container>
          <button type="button" className="mobile-menu-toggle" onClick={handleOpenMenu}>
            <ToggleButton></ToggleButton>
          </button>
          <Navbar.Brand>
            <Link to={'/dashboard'}>
              <img
                src={brandLogo}
                height="40"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Link>
          </Navbar.Brand>
          <div className="header-setting-menu d-flex align-items-center justify-content-center">
            <div className="desktop-login-name">
              <span className="text-white">{getUserData.name}</span>
            </div>
            <NavLink to="/notification" className="notification-icon mobile-notification">
              {getUserData.unread_notification_count > 0 && (
                <span className="notification-count">{getUserData.unread_notification_count}</span>
              )}
              <MobileNotificationIcon width={24} height={24} color={'fff'}></MobileNotificationIcon>
            </NavLink>
            <NavDropdown>
              <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                {t('page.header_logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
      <Sidebar t={t} isActive={isActive} setIsActive={setIsActive} brandLogo={brandLogo} />
    </>
  );
};
Header.propTypes = {
  t: PropTypes.any,
};

export { Header };
