import PropTypes from 'prop-types';
import { CreateGroupEvent } from './CreateGroupEvent';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import groupEventValidationSchema from '../Validation/GroupEventValidation';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useCreateGroupScheduleEvent, useDeleteGroupEvent, useEditGroupEvent, useUpdateGroupScheduleEvent } from '../../../../hooks';
import { toast } from 'react-toastify';
import { Col } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import Loader from 'common/components/Loader';
import 'assets/scss/page/_groupevent.scss';
import 'assets/scss/page/_calendar.scss';
import { useSelector } from 'react-redux';
import { loggedUser } from 'store/features/authSlice';
const MobileGroupActivity = ({ t }) => {
    const [userTimezone, setUserTimezone] = useState(moment.tz.guess());
    const getLoggedUserData = useSelector(loggedUser);
    if (getLoggedUserData && getLoggedUserData.time_zone) {
        setUserTimezone(getLoggedUserData.time_zone);
        moment.tz.setDefault(getLoggedUserData.time_zone);
    } else {
        // Use the current user's local time zone as the default
        const currentUserTimeZone = moment.tz.guess();
        // setUserTimezone(currentUserTimeZone);
        moment.tz.setDefault(currentUserTimeZone);
    }
    const { group_schedule_id, event_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmited, setIsFormSubmited] = useState(false);
    const [eventDetails, setEventDetails] = useState();
    const [eventEditData, setEventEditData] = useState([]);
    const [isEventEdit, setIsEventEdit] = useState(false);
    const [isFullDay, setIsFullDay] = useState(false);
    const [isMobileActivity, setIsMobileActivity] = useState(true);
    const initialized = useRef(false);
    const navigate = useNavigate();
    // formik form declaration
    const createEventFormik = useFormik({
        initialValues: {
            title: '',
            location: '',
            description: '',
            startDate: moment.utc().tz(userTimezone).format('YYYY-MM-DD'),
            endDate: moment.utc().tz(userTimezone).format('YYYY-MM-DD'),
            fullDay: false,
            startTime: moment.utc().tz(userTimezone).format('HH:mm'),
            endTime: moment.utc().tz(userTimezone).add(1, 'hour').format('HH:mm'),
        },
        validationSchema: groupEventValidationSchema,
        onSubmit: async (values) => {
            values['group_schedule_id'] = group_schedule_id;
            if (!values.fullDay) {
                values['start_date_time'] = moment(values.startDate + 'T' + values.startTime).toISOString();
                values['end_date_time'] = moment(values.endDate + 'T' + values.endTime).toISOString();
            } else {
                values['start_date_time'] = moment(values.startDate).startOf('day').toISOString();
                values['end_date_time'] = moment(values.endDate).endOf('day').toISOString();
            }
            setIsLoading(true);
            setIsFormSubmited(true);
            if (isEventEdit) {
                values.event_id = event_id;
                doUpdateGroupEvent(values);
            } else {
                doCreateGroupEvent(values);
            }
        },
    });
    const { mutate: doUpdateGroupEvent } = useUpdateGroupScheduleEvent(
        (res) => {
            setIsFormSubmited(false);
            toast.success(res.message);
            // refetchEvents();
            setIsLoading(false);
            navigate(`/group-calender/${group_schedule_id}`);
        },
        (res) => {
            setIsLoading(false);
            setIsFormSubmited(false);
            toast.error(res.message);
        }
    );
    const { mutate: doCreateGroupEvent } = useCreateGroupScheduleEvent(
        (res) => {
            setIsFormSubmited(false);
            toast.success(res.message);
            // refetchEvents();
            setIsLoading(false);
            navigate(`/group-calender/${group_schedule_id}`);
        },
        (res) => {
            setIsLoading(false);
            setIsFormSubmited(false);
            toast.error(res.message);
        }
    );
    const handleEventModelClose = () => {
        // setShowEventModel(false)
    };
    // Delete single event
    const deleteEvent = (event_id) => {
        withReactContent(Swal)
            .fire({
                title: <>{t('page.cancel_activity_text')}</>,
                html: (
                    <>
                        <p>{t('page.cancel_activity_title_text')}</p>
                    </>
                ),
                customClass: 'conformation-model',
                showCancelButton: true,
                cancelButtonText: (
                    <>
                        <FontAwesomeIcon icon={faClose} /> {t('calendar.cancel_btn_label')}
                    </>
                ),
                confirmButtonText: (
                    <>
                        <FontAwesomeIcon icon={faCheck} /> {t('calendar.continue_btn_label')}
                    </>
                ),
            })
            .then((result) => {
                if (result.isConfirmed) {
                    doDeleteGroupEvent({ event_id });
                }
            });
    };
    // Call delete single event api
    const { mutate: doDeleteGroupEvent } = useDeleteGroupEvent(
        (res) => {
            toast.success(res.message);
            // refetchEvents();
        },
        (res) => {
            toast.error(res.message);
        }
    );
    const { mutate: doEditEvent } = useEditGroupEvent(
        (res) => {
            setEventEditData(res.data);
            setIsLoading(false);
            const updatedValues = {
                title: res.data.title,
                location: res.data.location,
                description: res.data.description,
                fullDay: res.data.is_full_day_event,
                startDate: moment.utc(res.data.start_date).tz(userTimezone).format('YYYY-MM-DD'),
                endDate: moment.utc(res.data.end_date).tz(userTimezone).format('YYYY-MM-DD'),
                startTime: res.data.is_full_day_event
                    ? moment.utc().tz(userTimezone).format('HH:mm')
                    : moment.utc(res.data.start_date).tz(userTimezone).format('HH:mm'),
                endTime: res.data.is_full_day_event
                    ? moment.utc().tz(userTimezone).add(1, 'hour').format('HH:mm')
                    : moment.utc(res.data.end_date).tz(userTimezone).format('HH:mm'),
                updateAllEvent: false,
            };
            createEventFormik.setValues(updatedValues);

            setIsFullDay(res.data.is_full_day_event);
            setIsEventEdit(true);
        },
        (res) => {
            setIsLoading(false);
            toast.error(res.message);
        }
    );
    useEffect(() => {
        if (event_id && !initialized.current) {
            initialized.current = true;
            doEditEvent({ event_id });
            setIsEventEdit(true);
            setIsLoading(true);
        }
    }, [event_id, doEditEvent]);
    return (
        <>
            <Col xs="12" lg="12" className="mt-3 mb-3 mobile-create-activity-form px-4">
                <div className="calendar-content-wrapper">
                    <div className="sync-model-popup-content-wrapper activity-model">
                        <CreateGroupEvent
                            t={t}
                            handleEventModelClose={handleEventModelClose}
                            formik={createEventFormik}
                            isFormSubmited={isFormSubmited}
                            deleteEvent={deleteEvent}
                            setIsFullDay={setIsFullDay}
                            isFullDay={isFullDay}
                            isEventEdit={isEventEdit}
                            eventEditData={eventEditData}
                            isMobileActivity={isMobileActivity}
                            group_schedule_id={group_schedule_id} />
                    </div>

                </div>
            </Col>
            {isLoading ? <Loader /> : ''}
        </>
    );
};
MobileGroupActivity.propTypes = {
    t: PropTypes.any,
    createEvent: PropTypes.any,
};
export { MobileGroupActivity };