import PropTypes from 'prop-types';
import 'assets/scss/page/_schedule.scss';
import { useDispatch } from 'react-redux';
import { CreateGroupScheduleModel } from './Components';
import { useEffect, useRef, useState } from 'react';
import 'assets/scss/page/_groupschedule.scss';
import { useFormik } from 'formik';
import { EventColors, ShareScheduleType } from 'config';
import moment from 'moment';
import getValidationSchema from './CreateGroupScheduleValidation';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  useCreateGroupSchedule,
  useGetGroupCalendarAccount,
  useGetGroupScheduleDetails,
  useGetMemberList,
  useGetUnreadNotificationsCount,
  useLeaveGroup,
  useUpdateGroupSchedule,
} from '../../../hooks';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import LeaveGroupIcon from 'common/icons/LeaveGroupIcon';
import Loader from 'common/components/Loader';
import { updateUserData } from 'store/features/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
const MobileCreateGroupSchedule = ({ t }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isCustomType, setIsCustomType] = useState(false);
  const [editGroupSchedule, setEditGroupSchedule] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [calenderAccount, setCalenderAccount] = useState([]);
  // const [groupSchedule, setGroupSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editGroupScheduleDetails, setEditGroupScheduleDetails] = useState([]);
  const [step, setStep] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const { group_schedule_id } = useParams();
  const initialized = useRef(false);
  const navigate = useNavigate();
  const handleCloseModel = () => {
    if (editGroupSchedule) {
      formik.resetForm();
      setStep(1);
      setIsCustomType(false);
    }
    setShow(false);
    setEditGroupSchedule(false);
    // formik.resetForm();
  };
  const handleClearModel = () => {
    setShow(false);
    setEditGroupSchedule(false);
    formik.resetForm();
  };

  useGetMemberList((res) => {
    setMemberList(res.data);
  });

  // formik form declaration
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      members: [],
      calendar_preset_type: '',
      calendar_preset: '',
      start_time: moment().format('HH:mm'),
      end_time: moment().add(3, 'hour').format('HH:mm'),
      color: EventColors ? EventColors[0]['value'] : '',
      accounts: [],
    },
    validationSchema: getValidationSchema(editGroupSchedule),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (editGroupSchedule) {
        values.group_schedule_id = editGroupScheduleDetails.group_schedule_id;
        doUpdateScheduleCalender(values);
      } else {
        doCreateScheduleCalender(values);
      }

    },
  });

  // useEffect(() => {
  //   if (calenderAccount.length > 0) {
  //     formik.setFieldValue(
  //       'accounts',
  //       calenderAccount.map((account) => ({
  //         user_id: account.user_id,
  //         email: account.email,
  //         account_type: account.account_type_name,
  //         calendar_account_id: account.calendar_account_id,
  //         shared: false,
  //         shareType: ShareScheduleType[0]['value'],
  //       }))
  //     );
  //   }
  // }, [calenderAccount, formik]);

  const { mutate: doCreateScheduleCalender } = useCreateGroupSchedule(
    (res) => {
      setShow(false);
      toast.success(res.message);
      // refetchgroupSchedule();
      refetchUnreadCountNotification();
      setIsLoading(false);
      formik.resetForm();
      if (isMobile) {
        navigate('/group-schedules');
      }
    },
    (res) => {
      setIsLoading(false);
      toast.error(res.message);
    }
  );
  const { mutate: doUpdateScheduleCalender } = useUpdateGroupSchedule(
    (res) => {
      setIsLoading(false);
      toast.success(res.message);
      if (isMobile) {
        if(group_schedule_id){
          navigate('/group-calender/'+group_schedule_id);
        }else{
          navigate('/group-schedules');
        }
        
      }
    }, (error) => {
      toast.error(error.message);
      setIsLoading(false);
    }
  );

  useGetGroupCalendarAccount(
    {},
    (res) => {
      setIsLoading(false);
      setCalenderAccount(res.data);
      if (res.data.length > 0) {
        formik.setFieldValue(
          'accounts',
          calenderAccount.map((account) => ({
            user_id: account.user_id,
            email: account.email,
            account_type: account.account_type_name,
            calendar_account_id: account.calendar_account_id,
            shared: false,
            shareType: ShareScheduleType[0]['value'],
            account_type_id: account.account_type,
          }))
        );
      }
    },
    (error) => {
      setIsLoading(false);
    }
  );

  const leaveGroup = (groupId) => {
    withReactContent(Swal)
      .fire({
        title: <>{t('group_schedule.exit_group_label')}</>,
        html: (
          <>
            <p>
              {t('group_schedule.exit_group_description')}
            </p>
          </>
        ),
        customClass: 'conformation-model leave-conformation-model',
        showCancelButton: true,
        cancelButtonText: (
          <>
            <FontAwesomeIcon icon={faClose} />
            {t('group_schedule.exit_group_cancel_btn')}
          </>
        ),
        confirmButtonText: (
          <>
            <LeaveGroupIcon />
            {t('group_schedule.exit_group_exit_group_btn')}
          </>
        ),
      })
      .then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          doExitGroup({ groupId: groupId });
        }
      });
  };

  const { mutate: doExitGroup } = useLeaveGroup(
    (res) => {
      toast.success(res.message);
      // refetchgroupSchedule();
      refetchUnreadCountNotification();
      setIsLoading(false);
      setShow(false);
      if (isMobile) {
        navigate('/group-schedules');
      }
    },
    (error) => {
      setIsLoading(false);
      toast.error(error.message);
      if (isMobile) {
        navigate('/group-schedules');
      }
    }
  );

  const { mutate: doGetGroupScheduleDetails } = useGetGroupScheduleDetails(
    (res) => {
      formik.resetForm();
      setEditGroupScheduleDetails(res.data);
      const updatedValues = {
        title: res.data.title,
        description: res.data.description,
        color: res.data.color,
        members: [],
        accounts: [],
      };
      formik.setValues(updatedValues);
      if (res.data.members.length > 0) {
        formik.setFieldValue('members', res.data.members);
      }
      if (res.data.calendarAccount.length > 0) {
        formik.setFieldValue(
          'accounts',
          res.data.calendarAccount.map((account) => ({
            user_id: account.user_id,
            email: account.email,
            account_type: account.account_type_name,
            calendar_account_id: account.calendar_account_id,
            shared: account.is_shared,
            shareType: account.share_type,
            account_type_id: account.account_type,
          }))
        );
      }
      setIsLoading(false);
    },
    (error) => {
      setIsLoading(false);
      toast.error(error.message);
      if (isMobile) {
        navigate('/group-schedules');
      }
    }
  );

  const { refetch: refetchUnreadCountNotification } = useGetUnreadNotificationsCount(
    (res) => {
      const dataStore = {
        unread_notification_count: res.data.unread_count,
        calender_account: res.data.calendars,
      };
      dispatch(updateUserData(dataStore));
    },
    (error) => {
    }
  );

  useEffect(() => {
    const element = document.querySelector('.login-user-dashboard');
    if (element) {
      element.classList.remove('login-user-dashboard');
    }
    if (!initialized.current) {
      if (group_schedule_id) {
        setIsLoading(true);
        setEditGroupSchedule(true);
        doGetGroupScheduleDetails({ group_schedule_id: group_schedule_id });
      }
    }
    const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width as needed
    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
    };
    // Initial check
    handleMediaChange(mediaQuery);

    // Add listener
    mediaQuery.addListener(handleMediaChange);

    // Cleanup listener on unmount
    return () => mediaQuery.removeListener(handleMediaChange);
  }, [group_schedule_id, doGetGroupScheduleDetails]);
  return (
    <>
      <CreateGroupScheduleModel
        t={t}
        show={show}
        handleCloseModel={handleCloseModel}
        formik={formik}
        editGroupSchedule={editGroupSchedule}
        leaveGroup={leaveGroup}
        memberList={memberList}
        isCustomType={isCustomType}
        setIsCustomType={setIsCustomType}
        editGroupScheduleDetails={editGroupScheduleDetails}
        handleClearModel={handleClearModel}
        step={step}
        setStep={setStep}
        isMobile={isMobile}
      />
      {isLoading ? <Loader /> : ''}
    </>
  );
};
MobileCreateGroupSchedule.propTypes = {
  t: PropTypes.any,
};
export default MobileCreateGroupSchedule;
