import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial Values of globally declared states in redux
 */
const intialState = {
  setting_get: false,
};

/**
 * Slice for Setting Data
 */
export const settingSlice = createSlice({
  name: 'setting',
  initialState: intialState,
  /**
   * Reducer functions for settings info
   */
  reducers: {
    addSetting: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
      state.setting_get = true;

      return state;
    },
    clearSetting: () => intialState,
  },
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;
