import React from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY_SLUG, TERM_CONDITION_SLUG, ABOUT_US_SLUG } from 'config';
const AuthFooter = ({ t }) => {
  return (
    <>
      <Col xs="12" lg="12" className="login-footer-wrapper">
        <ListGroup>
          <ListGroup.Item>
            <Link to={`/page/${PRIVACY_POLICY_SLUG}`} className="-link">
              {t('page.privacy_policy_text')}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to={`/page/${TERM_CONDITION_SLUG}`} className="forgot-password-link">
              {t('page.term_condition_text')}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to={`/page/${ABOUT_US_SLUG}`} className="forgot-password-link">
              {t('page.about_us_text')}
            </Link>
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </>
  );
};
AuthFooter.propTypes = {
  children: PropTypes.any,
};

export { AuthFooter };
