import React from 'react';
import PropTypes from 'prop-types';
const PlusIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#3880FF';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.625 9.99634C16.625 6.06274 13.4336 2.87134 9.5 2.87134C5.56641 2.87134 2.375 6.06274 2.375 9.99634C2.375 13.9299 5.56641 17.1213 9.5 17.1213C13.4336 17.1213 16.625 13.9299 16.625 9.99634Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.4688 9.99707H6.53125M9.5 7.02832V12.9658V7.02832Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
PlusIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default PlusIcon;
