import { createSlice } from '@reduxjs/toolkit';
import { setAuthToken } from 'libs/HttpClient';

/**
 * Initial Values of globally declared states in redux
 */
const initialState = {
  id:'',
  name: '',
  isUserLoggedIn: false,
  email: '',
  lastLoginTime: '',
  isEmailVerify: '',
  accessToken: '',
  uid: '',
  is_redirect_dashboard : true,
  unread_notification_count : 0
};

/**
 * Slice for authentication Info
 */
export const authSlice = createSlice({
  name: 'user',
  initialState: initialState,
  /**
   * Reducer functions
   */
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.id = payload.id;
      state.name = payload.name;
      state.isUserLoggedIn = true;
      state.email = payload.email;
      state.lastLoginTime = payload.lastLoginTime;
      state.isEmailVerify = payload.isEmailVerify;
      state.accessToken = payload.accessToken;
      state.uid = payload.uid;
      state.time_zone = payload.time_zone;
      state.unread_notification_count =payload.unread_notification_count ?? 0;
      setAuthToken(payload.accessToken);
      return state;
    },
    updateUserData: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
      state.user = payload.userData;
      return state;
    },
    logoutSuccess: () => initialState,
  },
});
export const { loginSuccess, logoutSuccess, updateUserData } = authSlice.actions;
export const loggedUser = (state) => state.user;
export default authSlice.reducer;
