import React from 'react';
import PropTypes from 'prop-types';
const MobileNotificationIcon = ({ width, height }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 2.24988C6.62404 2.24988 2.25013 6.62378 2.25013 11.9999C2.25013 17.376 6.62404 21.7499 12.0001 21.7499C17.3762 21.7499 21.7501 17.376 21.7501 11.9999C21.7501 6.62378 17.3762 2.24988 12.0001 2.24988ZM12.0001 17.9999C11.0204 17.9999 10.2414 17.5846 10.1368 16.7071C10.1341 16.6808 10.1369 16.6543 10.145 16.6293C10.1532 16.6042 10.1666 16.5811 10.1843 16.5616C10.202 16.542 10.2236 16.5264 10.2477 16.5158C10.2719 16.5052 10.298 16.4997 10.3243 16.4999H13.6736C13.7002 16.4997 13.7266 16.5052 13.7509 16.516C13.7753 16.5268 13.797 16.5426 13.8148 16.5625C13.8325 16.5824 13.8458 16.6058 13.8538 16.6312C13.8618 16.6566 13.8643 16.6834 13.8611 16.7099C13.7415 17.571 12.9695 17.9999 12.0001 17.9999ZM16.5939 15.7499H7.40638C6.85138 15.7499 6.56263 15.0468 6.88185 14.6718C7.64732 13.7652 8.18825 13.3841 8.18825 10.8843C8.18825 8.59347 9.39482 7.78066 10.3914 7.3841C10.46 7.35552 10.5218 7.31289 10.5729 7.25896C10.624 7.20503 10.6632 7.14099 10.6881 7.07097C10.8611 6.49675 11.3504 5.99988 12.0001 5.99988C12.6498 5.99988 13.1383 6.49675 13.3126 7.07144C13.3376 7.14145 13.377 7.20546 13.4282 7.25938C13.4793 7.3133 13.5412 7.35594 13.6098 7.38457C14.604 7.78019 15.8129 8.59441 15.8129 10.8847C15.8129 13.3846 16.3534 13.7657 17.1189 14.6722C17.4348 15.0468 17.1456 15.7499 16.5939 15.7499Z"
        fill="#EBF3F7"
      />
    </svg>
  );
};
MobileNotificationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default MobileNotificationIcon;
