// import libs
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SignUpPage, ForgotPassword, LoginPage } from 'pages/Auth';
import CallBackAction from 'pages/Firebase/CallBackAction';
import PublicRoute from './PublicRoute';
import CmsRoutes from 'pages/Cms/CMSRoutes';
import { useDispatch } from 'react-redux';
import { useFetchPageSettingMetaData } from '../hooks';
import { addSetting } from 'store/features/settingSlice';
import PrivateRoute from './PrivateRoute';
import { EditProfile } from 'pages/MyAccount';
import PageNotFound from 'common/components/PageNotFound';
import { SyncSchedules } from 'pages/MySchedules';
import { Dashboard, MobileCreateActivity } from 'pages/Dashboard';
import { CreateGroupSchedule } from 'pages/GroupSchedule';
import { Notification } from 'pages/Notification';
import MobileCreateGroupSchedule from 'pages/GroupSchedule/CreateGroupSchedule/MobileCreateGroupSchedule';
import { GroupCalender } from 'pages/GroupCalender';
import { MobileGroupActivity } from 'pages/GroupCalender/Calender/Components/MobileGroupActivity';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useFetchPageSettingMetaData(
    {},
    (res) => {
      dispatch(addSetting(res.data));
    },
    (error) => {
      console.log(error);
    }
  );

  return (
    <Router basename={'/'}>
      <Routes>
        <Route element={<PublicRoute showFooter={true} />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path="/login" element={<LoginPage t={t} />} />
        </Route>
        <Route element={<PublicRoute showFooter={true} />}>
          <Route path="/page/*" element={<CmsRoutes t={t} />} />
        </Route>
        <Route element={<PublicRoute showFooter={false} />}>
          <Route exact path="/sign-up" element={<SignUpPage t={t} />} />
          <Route exact path="/forgot-password" element={<ForgotPassword t={t} />} />
          <Route exact path="/action" element={<CallBackAction t={t} />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<Dashboard t={t} />} />
          <Route exact path="/create-activity" element={<MobileCreateActivity t={t} />} />
          <Route exact path="/edit-activity/:event_id" element={<MobileCreateActivity t={t} />} />
          <Route exact path="/edit-profile" element={<EditProfile t={t} />} />
          <Route exact path="/sync-schedules" element={<SyncSchedules t={t} />} />
          <Route exact path="/group-schedules" element={<CreateGroupSchedule t={t} />} />
          <Route exact path="/create-group-schedules" element={<MobileCreateGroupSchedule t={t} />} />
          <Route exact path="/edit-group-schedules/:group_schedule_id" element={<MobileCreateGroupSchedule t={t} />} />
          <Route exact path="/notification" element={<Notification t={t} />} />
          <Route exact path="/group-calender/:group_schedule_id" element={<GroupCalender t={t} />} />
          <Route exact path="/create-group-activity/:group_schedule_id" element={<MobileGroupActivity t={t} />} />
          <Route exact path="/edit-group-activity/:group_schedule_id/:event_id" element={<MobileGroupActivity t={t} />} />
        </Route>
        <Route>
          {/* Page Not Found Routes */}
          <Route path="*" element={<PageNotFound t={t} />} from="admin" />
        </Route>
      </Routes>
    </Router>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
  showFooter: PropTypes.bool,
};
export default PagesRoutes;
