import React from 'react';
import PropTypes from 'prop-types';
const BackIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '12px';
  const svgHeight = height ? `${height}px` : '12px';
  const svgColor = color ? `#${color}` : '#64748B';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 9.5L1 5.5L5 1.5"
        stroke={svgColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
BackIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default BackIcon;
