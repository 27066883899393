// import * as Yup from 'yup';

// const groupEventValidationSchema = Yup.object({
//     title: Yup.string().max(150, 'form_validation_message.title_length_validation').required('form_validation_message.title_required'),
//     // location: Yup.string().max(250, 'form_validation_message.location_length_validation').required('form_validation_message.location_required'),
//     // description: Yup.string().required('form_validation_message.description_required'),
//     startDate: Yup.date().required('form_validation_message.start_date_required'),
//     endDate: Yup.date()
//         .required('form_validation_message.end_date_required')
//         .min(Yup.ref('startDate'), 'form_validation_message.end_date_min_validation'),
//     fullDay: Yup.boolean(),
//     startTime: Yup.string().when('fullDay', {
//         is: false,
//         then: () => Yup.string().required('form_validation_message.start_time_required'),
//         otherwise: () => Yup.string().notRequired(),
//     }),
//     endTime: Yup.string().when('fullDay', {
//         is: false,
//         then: () =>
//             Yup.string()
//                 .required('form_validation_message.end_time_required')
//                 .test('is-after-start', 'form_validation_message.end_time_after_start', function (endTime) {
//                     const { startTime, startDate, endDate } = this.parent;
//                     if (new Date(endDate) > new Date(startDate)) {
//                         return true;
//                     } else {
//                         if (!startTime || !endTime) return true; // Let required validator handle empty values
//                         return startTime <= endTime;
//                     }
//                 }),
//         otherwise: () => Yup.string().notRequired(),
//     }),
// });

// export default groupEventValidationSchema;


import * as Yup from 'yup';

const groupEventValidationSchema = (calendarDetails) => Yup.object({
  title: Yup.string().max(150, 'form_validation_message.title_length_validation').required('form_validation_message.title_required'),
  startDate: Yup.date().required('form_validation_message.start_date_required'),
  endDate: Yup.date()
    .required('form_validation_message.end_date_required')
    .min(Yup.ref('startDate'), 'form_validation_message.end_date_min_validation'),
  fullDay: Yup.boolean(),

  startTime: Yup.string().when('fullDay', {
    is: false,
    then: () => Yup.string()
      .required('form_validation_message.start_time_required')
      .test('is-after-custom-time', function (startTime) {
        // Extract dynamic start_time from calendarDetails
        const groupSchedules = calendarDetails?.group_schedules;

        // Fixed validation for calendar_preset === 5
        if (groupSchedules?.calendar_preset === 5) {
          const [hours, minutes] = startTime.split(':');
          const startTimeInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
          const requiredTimeInMinutes = 17 * 60; // 17:00 in minutes

          // Create fixed message for calendar_preset === 5
          if (startTimeInMinutes < requiredTimeInMinutes) {
            return this.createError({
              path: 'startTime',
              message: 'Start time must be 17:00 or later',
            });
          }
          return true;
        }

        // Dynamic validation for calendar_preset === 1
        if (groupSchedules?.calendar_preset === 1) {
          const [requiredHours, requiredMinutes] = groupSchedules?.start_time.split(':');
          const [startHours, startMinutes] = startTime.split(':');
          const requiredTimeInMinutes = parseInt(requiredHours, 10) * 60 + parseInt(requiredMinutes, 10);
          const startTimeInMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);

          // Create dynamic message using the required start_time
          const message = `Start time must be ${groupSchedules.start_time} or later`;

          if (startTimeInMinutes < requiredTimeInMinutes) {
            return this.createError({
              path: 'startTime',
              message,
            });
          }
          return true;
        }

        return true; // Allow other times if the condition isn't met
      }),
    otherwise: () => Yup.string().notRequired(),
  }),

  endTime: Yup.string().when('fullDay', {
    is: false,
    then: () => Yup.string()
      .required('form_validation_message.end_time_required')
      .test('is-before-custom-end-time', function (endTime) {
        const groupSchedules = calendarDetails?.group_schedules;
        // Custom end time validation for calendar_preset === 1
        if (groupSchedules?.calendar_preset === 1) {
          const [requiredEndHours, requiredEndMinutes] = groupSchedules?.end_time.split(':');
          const [endHours, endMinutes] = endTime.split(':');
          const requiredEndTimeInMinutes = parseInt(requiredEndHours, 10) * 60 + parseInt(requiredEndMinutes, 10);
          const endTimeInMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

          const message = `End time must be ${groupSchedules.end_time} or earlier`;

          if (endTimeInMinutes > requiredEndTimeInMinutes) {
            return this.createError({
              path: 'endTime',
              message,
            });
          }
          return true;
        } else {
          const { startTime, startDate, endDate } = this.parent;
          if (new Date(endDate) > new Date(startDate)) {
            return true;
          } else {
            const message = `End time is invalid`;
            if (!startTime || !endTime){
              return true; // Let required validator handle empty values
            } else if (endTime < startTime){
              return this.createError({
                path: 'endTime',
                message,
              });
            }
            return startTime <= endTime;
          }
        }
      }),
    otherwise: () => Yup.string().notRequired(),
  }),
});

export default groupEventValidationSchema;
