import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AuthService } from 'api';

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useUserSignUp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.signUp, {
    onSuccess,
    onError,
  });
};
const useUpdateEmailVerificationStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.updateEmailVerificationStatus, {
    onSuccess,
    onError,
  });
};

const useScocialSignUp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.socialSignUp, {
    onSuccess,
    onError,
  });
};

const useResetPasswordCheckAccountSignupType = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resetPasswordCheckAccountSignupType, {
    onSuccess,
    onError,
  });
};

const useGetUserByEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.getUserByEmail, {
    onSuccess,
    onError,
  });
};
const useUpdateUserProfile = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.updateUserProfile, {
    onSuccess,
    onError,
  });
};
const useUpdateSignupType = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.updateSignupType, {
    onSuccess,
    onError,
  });
};
const useResendVerificationEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resendVerificationEmail, {
    onSuccess,
    onError,
  });
};
export {
  useUserSignUp,
  useUpdateEmailVerificationStatus,
  useScocialSignUp,
  useResetPasswordCheckAccountSignupType,
  useGetUserByEmail,
  useUpdateUserProfile,
  useUpdateSignupType,
  useResendVerificationEmail
};
