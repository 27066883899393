import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
const ButtonSpinner = ({ size, animation, element }) => {
  return (
    <Spinner
      as={element ?? 'span'}
      animation={animation ?? 'border'}
      size={size ?? 'sm'}
      role="status"
      aria-hidden="true"
    />
  );
};
ButtonSpinner.propTypes = {
  size: PropTypes.any,
  animation: PropTypes.any,
  element: PropTypes.any,
};
export default ButtonSpinner;
