import React from 'react';
import PropTypes from 'prop-types';
const CalendarIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill='none'
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 3.74915H4.5C3.25736 3.74915 2.25 4.7565 2.25 5.99915V19.4991C2.25 20.7418 3.25736 21.7491 4.5 21.7491H19.5C20.7426 21.7491 21.75 20.7418 21.75 19.4991V5.99915C21.75 4.7565 20.7426 3.74915 19.5 3.74915Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M14.9766 10.8733C14.9766 11.4817 14.4834 11.9749 13.875 11.9749C13.2666 11.9749 12.7734 11.4817 12.7734 10.8733C12.7734 10.2649 13.2666 9.77173 13.875 9.77173C14.4834 9.77173 14.9766 10.2649 14.9766 10.8733Z"
        fill={svgColor}
        stroke={svgColor}
        strokeWidth="0.046875"
      />
      <path
        d="M17.625 11.9983C18.2463 11.9983 18.75 11.4946 18.75 10.8733C18.75 10.252 18.2463 9.74829 17.625 9.74829C17.0037 9.74829 16.5 10.252 16.5 10.8733C16.5 11.4946 17.0037 11.9983 17.625 11.9983Z"
        fill={svgColor}
      />
      <path
        d="M13.875 15.7483C14.4963 15.7483 15 15.2446 15 14.6233C15 14.002 14.4963 13.4983 13.875 13.4983C13.2537 13.4983 12.75 14.002 12.75 14.6233C12.75 15.2446 13.2537 15.7483 13.875 15.7483Z"
        fill={svgColor}
      />
      <path
        d="M17.625 15.7483C18.2463 15.7483 18.75 15.2446 18.75 14.6233C18.75 14.002 18.2463 13.4983 17.625 13.4983C17.0037 13.4983 16.5 14.002 16.5 14.6233C16.5 15.2446 17.0037 15.7483 17.625 15.7483Z"
        fill={svgColor}
      />
      <path
        d="M6.375 15.7483C6.99632 15.7483 7.5 15.2446 7.5 14.6233C7.5 14.002 6.99632 13.4983 6.375 13.4983C5.75368 13.4983 5.25 14.002 5.25 14.6233C5.25 15.2446 5.75368 15.7483 6.375 15.7483Z"
        fill={svgColor}
      />
      <path
        d="M10.125 15.7483C10.7463 15.7483 11.25 15.2446 11.25 14.6233C11.25 14.002 10.7463 13.4983 10.125 13.4983C9.50368 13.4983 9 14.002 9 14.6233C9 15.2446 9.50368 15.7483 10.125 15.7483Z"
        fill={svgColor}
      />
      <path
        d="M6.375 19.4983C6.99632 19.4983 7.5 18.9946 7.5 18.3733C7.5 17.752 6.99632 17.2483 6.375 17.2483C5.75368 17.2483 5.25 17.752 5.25 18.3733C5.25 18.9946 5.75368 19.4983 6.375 19.4983Z"
        fill={svgColor}
      />
      <path
        d="M10.125 19.4983C10.7463 19.4983 11.25 18.9946 11.25 18.3733C11.25 17.752 10.7463 17.2483 10.125 17.2483C9.50368 17.2483 9 17.752 9 18.3733C9 18.9946 9.50368 19.4983 10.125 19.4983Z"
        fill={svgColor}
      />
      <path
        d="M13.875 19.4983C14.4963 19.4983 15 18.9946 15 18.3733C15 17.752 14.4963 17.2483 13.875 17.2483C13.2537 17.2483 12.75 17.752 12.75 18.3733C12.75 18.9946 13.2537 19.4983 13.875 19.4983Z"
        fill={svgColor}
      />
      <path
        d="M18 2.24829V3.74829M6 2.24829V3.74829V2.24829Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.75 7.49915H2.25" stroke={svgColor} strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};
CalendarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default CalendarIcon;
