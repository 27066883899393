import client from 'libs/HttpClient';

class CalendarService {
  static authenticateUserCalendarCode(data) {
    return client.post('user/authenticate-user-calendar-code',data);
  }
  static getCalendarAccount(data) {
    return client.get('user/get-calendar-account',data);
  }
  static trashCalendarAccount(data) {
    return client.post('user/trash-calendar-account',data);
  }
  static getCalendarEventList(data) {
    return client.get('user/get-calendar-event-list',{ params: data });
  }


  // outlook calendar service

  static authorizeOutloolCalendarAccount(data) {
    return client.post('user/outlook/authorize',data);
  }

  // synco calendar service
  static createSyncoEvent(data) {
    return client.post('user/synco/calendar',data);
  }
  static deleteSyncoEvent(data) {
    return client.delete(`user/synco/calendar/${data.event_id}`);
  }
  static editSyncoEvent(data) {
    return client.get(`user/synco/calendar/${data.event_id}`);
  }
  static updateSyncoEvent(data) {
    return client.patch(`user/synco/calendar/${data.event_id}`,data);
  }
  static deleteEventSeries(data) {
    return client.delete(`user/synco/delete-calendar-series/${data.recurring_event_id}`,);
  }

  // apple calendar service
  static createAppleCalendarAccount(data) {
    return client.post('user/apple/create-account',data);
  }
  static syncAppleCalendar() {
    return client.get('user/apple/sync-calender');
  }

  // group schedule calender 
  static getMemberList() {
    return client.get('user/groupschedule/get-member');
  }
  static createGroupSchedule(data) {
    return client.post('user/groupschedule/create-group-schedule',data);
  }

  static getNotificationList() {
    return client.get('user/groupschedule/notifications');
  }
  static acceptNotification(data) {
    return client.post(`user/groupschedule/notifications/${data.id}/accept`);
  }
  static declineNotification(data) {
    return client.post(`user/groupschedule/notifications/${data.id}/reject`);
  }
  static deleteNotification(data) {
    return client.post(`user/groupschedule/notifications/${data.id}/delete`);
  }
  static getCalendarsWithActiveMembers() {
    return client.get('user/groupschedule/calendars-with-active-members');
  }
  static leaveGroup(data) {
    return client.post(`user/groupschedule/${data.groupId}/leave`);
  }
  static getGroupScheduleDetails(data) {
    return client.get(`user/groupschedule/${data.group_schedule_id}`);
  }
  static updateGroupSchedule(data) {
    return client.post(`user/groupschedule/update-group-schedule/${data.group_schedule_id}`,data);
  }
  static getUnreadNotificationsCount() {
    return client.get('user/groupschedule/get-unread-notifications-count');
  }
  static getMarkAsReadNotifications() {
    return client.get('user/groupschedule/mark-as-read-notifications');
  }
  static getGroupCalender(data) {
    return client.get(`user/groupschedule/group-calender/${data.group_schedule_id}`);
  }
  // group schedule event
  static createGroupScheduleEvent(data) {
    return client.post('user/groupschedule/create-group-schedule-event',data);
  }
  static updateGroupScheduleEvent(data) {
    return client.patch(`user/groupschedule/update-group-schedule-event/${data.event_id}`,data);
  }
  static getGroupCalendarEventList(data) {
    return client.get('user/groupschedule/get-group-calendar-event-list',{ params: data });
  }
  static acceptGroupEventInvitation(data) {
    return client.post('user/groupschedule/group-schedule-event/accept',data);
  }
  static rejectGroupEventInvitation(data) {
    return client.post('user/groupschedule/group-schedule-event/reject',data);
  }
  static editGroupEvent(data) {
    return client.get(`user/groupschedule/group-event/${data.event_id}`);
  }
  static deleteGroupEvent(data) {
    return client.delete(`user/groupschedule/event/${data.event_id}`);
  }
  static getGroupCalendarAccount(data) {
    return client.get('user/groupschedule/get-calendar-account',data);
  }
}

export { CalendarService };
