import React from 'react';
export default function LoadingSpinner() {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner"></div>
      </div>
    </div>
  );
}
