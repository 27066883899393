import React from 'react';
import PropTypes from 'prop-types';
const LeaveGroupIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '20px';
  const svgHeight = height ? `${height}px` : '20px';
  const svgColor = color ? `#${color}` : '#fff';
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.95911 10.5935V10.6169H6.98254H12.9337V16.0857C12.9337 16.1975 12.8893 16.3048 12.8102 16.384C12.731 16.4631 12.6237 16.5075 12.5118 16.5075H2.12122C2.00933 16.5075 1.90202 16.4631 1.82291 16.384C1.74379 16.3048 1.69934 16.1975 1.69934 16.0857V3.91378C1.69934 3.80189 1.74379 3.69459 1.82291 3.61547C1.90202 3.53635 2.00933 3.49191 2.12122 3.49191H12.5118C12.6237 3.49191 12.731 3.53635 12.8102 3.61547C12.8893 3.69459 12.9337 3.80189 12.9337 3.91378V9.38253H6.98254H6.95911V9.40597V10.5935ZM16.0675 9.38939L13.7091 7.03097L14.5157 6.22433L18.2908 9.99972L14.5157 13.7751L13.7091 12.9685L16.0675 10.61L16.1075 10.57H16.051H12.9806V9.42941H16.051H16.1075L16.0675 9.38939Z"
        fill={svgColor}
        stroke="white"
        strokeWidth="0.046875"
      />
    </svg>
  );
};
LeaveGroupIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default LeaveGroupIcon;
