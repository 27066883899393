import React from 'react';
import PropTypes from 'prop-types';
const NotificationMenuIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '28px';
  const svgHeight = height ? `${height}px` : '28px';
  const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0001 11.9977C21.0001 7.02899 16.9689 2.99774 12.0001 2.99774C7.03137 2.99774 3.00012 7.02899 3.00012 11.9977C3.00012 16.9665 7.03137 20.9977 12.0001 20.9977C16.9689 20.9977 21.0001 16.9665 21.0001 11.9977Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.1188 14.6714C16.3533 13.7649 15.8128 13.3838 15.8128 10.8839C15.8128 8.59364 14.6044 7.77942 13.6097 7.38379C13.5411 7.35516 13.4792 7.31253 13.4281 7.25861C13.3769 7.20469 13.3375 7.14067 13.3125 7.07067C13.1386 6.49645 12.6506 5.99957 12 5.99957C11.3494 5.99957 10.8605 6.49645 10.6875 7.07114C10.6627 7.14115 10.6234 7.20519 10.5723 7.25912C10.5212 7.31305 10.4594 7.35568 10.3908 7.38426C9.39517 7.78082 8.18767 8.59364 8.18767 10.8844C8.18767 13.3843 7.64673 13.7654 6.88127 14.6719C6.56392 15.0469 6.8508 15.75 7.4058 15.75H16.5938C17.1455 15.7496 17.4347 15.0464 17.1188 14.6714ZM10.3238 16.4996C10.2974 16.4994 10.2713 16.5048 10.2472 16.5155C10.223 16.5261 10.2014 16.5417 10.1837 16.5613C10.166 16.5808 10.1526 16.6039 10.1445 16.629C10.1363 16.654 10.1335 16.6805 10.1363 16.7068C10.2417 17.5843 11.0208 17.9996 12 17.9996C12.9689 17.9996 13.741 17.5707 13.8591 16.7096C13.8623 16.6831 13.8598 16.6563 13.8518 16.6309C13.8438 16.6055 13.8305 16.5821 13.8128 16.5622C13.7951 16.5423 13.7733 16.5265 13.7489 16.5157C13.7246 16.5049 13.6982 16.4994 13.6716 16.4996H10.3238Z"
        fill={svgColor}
      />
    </svg>
  );
};
NotificationMenuIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default NotificationMenuIcon;
