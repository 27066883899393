import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthLayout } from './../common/layouts';
import { useSelector } from 'react-redux';
import { loader } from 'store/features/loaderSlice';
import Loader from 'common/components/Loader';
import { loggedUser } from 'store/features/authSlice';
const PublicRoute = (t) => {
  const checkLoggenInUser = useSelector(loggedUser);
  const checkLoading = useSelector(loader);
  /**
   * Checking if user is registered user or not
   */
  const isUserLoggedIn = checkLoggenInUser.isUserLoggedIn;
  return !isUserLoggedIn ? (
    <AuthLayout showFooter={t.showFooter}>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </AuthLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/dashboard',
      }}
      replace
    />
  );
};

export default PublicRoute;
