import React from 'react';
import PropTypes from 'prop-types';
const ArrowBack = ({ width, height }) => {
  const svgWidth = width ? `${width}px` : '20px';
  const svgHeight = height ? `${height}px` : '20px';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.55469 15.3447L4.21094 10.001L9.55469 4.65723"
        stroke="white"
        strokeWidth="1.77497"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95312 9.99951H15.7891"
        stroke="white"
        strokeWidth="1.77497"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
ArrowBack.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default ArrowBack;
