import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { SettingsService } from 'api';
/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useFetchPageSettingMetaData = (queryParameters, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`setting-data`, [queryParameters]],
    () => SettingsService.getPageSettingMetaData(queryParameters),
    {
      onSuccess,
      onError,
    }
  );
};

export { useFetchPageSettingMetaData };
