import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BackIcon from 'common/icons/BackIcon';
import { Form } from 'react-bootstrap';
import { FormStep1 } from './FormStep1';
import { FormStep2 } from './FormStep2';


const CreateGroupScheduleModel = ({
  t,
  show,
  handleCloseModel,
  formik,
  editGroupSchedule,
  leaveGroup,
  memberList,
  isCustomType,
  setIsCustomType,
  editGroupScheduleDetails,
  handleClearModel,
  step,
  setStep,
  isMobile
}) => {
  const firstStepFields = [
    'title',
    'description',
    'calendar_preset_type',
    'calendar_preset',
    'start_time',
    'end_time',
    'members',
  ];
  const nextStep = async () => {
    const errors = await formik.validateForm();
    const hasFirstStepErrors = firstStepFields.some((field) => errors.hasOwnProperty(field));
    if (hasFirstStepErrors) {
      // Set all relevant fields as touched to show errors
      firstStepFields.forEach((field) => formik.setFieldTouched(field, true));
    } else {
      setStep(step + 1);
    }
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const handleClear = (fieldName) => {
    formik.setFieldValue(fieldName, '');
    formik.setFieldTouched(fieldName, false);
  };
  const renderStep = () => {
    if (editGroupSchedule) {
      return (
        <>
          <FormStep1
            t={t}
            formik={formik}
            handleClear={handleClear}
            nextStep={nextStep}
            handleCloseModel={handleCloseModel}
            editGroupSchedule={editGroupSchedule}
            memberList={memberList}
            isCustomType={isCustomType}
            setIsCustomType={setIsCustomType}
            handleClearModel={handleClearModel}
          />
          <FormStep2
            t={t}
            formik={formik}
            handleClear={handleClear}
            prevStep={prevStep}
            editGroupSchedule={editGroupSchedule}
            leaveGroup={leaveGroup}
            editGroupScheduleDetails={editGroupScheduleDetails}
          />
        </>
      );
    }
    switch (step) {
      case 1:
        return (
          <>
            <FormStep1
              t={t}
              formik={formik}
              handleClear={handleClear}
              nextStep={nextStep}
              handleCloseModel={handleCloseModel}
              editGroupSchedule={editGroupSchedule}
              memberList={memberList}
              isCustomType={isCustomType}
              setIsCustomType={setIsCustomType}
              handleClearModel={handleClearModel}
              isMobile={isMobile}></FormStep1>
          </>
        );
      case 2:
        return (
          <FormStep2
            t={t}
            formik={formik}
            handleClear={handleClear}
            prevStep={prevStep}
            editGroupSchedule={editGroupSchedule}
            leaveGroup={leaveGroup}
            editGroupScheduleDetails={editGroupScheduleDetails}></FormStep2>
        );
      default:
        return null;
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          <div className="calendar-content-wrapper">
            <div className="sync-model-popup-content-wrapper activity-model">
              <div className="form-title-content-wrapper">
                <h3 className="mb-40 d-flex align-items-center">
                  <Link className="group-schedule-back-btn back-btn-mobile" to={'/group-schedules'}>
                    <BackIcon />
                  </Link>
                  {editGroupSchedule ? (
                    <>{t('group_schedule.group_schedule_edit_form_title')}</>
                  ) : (
                    <>{t('group_schedule.group_schedule_create_form_title')}</>
                  )}
                </h3>
              </div>
              <div className="group-schedule-form">
                <Form
                  onSubmit={formik.handleSubmit}
                  className="page-form-wrapper activity-model-popup">
                  {renderStep()}
                </Form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Modal
          show={show}
          onHide={handleCloseModel}
          className="sync-model-popup group-schedule-model-wrapper">
          <Modal.Body className="group-schedule-model-content">
            <div className="calendar-content-wrapper">
              <div className="sync-model-popup-content-wrapper activity-model">
                <div className="form-title-content-wrapper">
                  <h3 className="mb-40 d-flex align-items-center">
                    <Link className="back-btn-mobile" to={'/group-schedules'}>
                      <BackIcon />
                    </Link>
                    {editGroupSchedule ? (
                      <>{t('group_schedule.group_schedule_edit_form_title')}</>
                    ) : (
                      <>{t('group_schedule.group_schedule_create_form_title')}</>
                    )}
                  </h3>
                </div>
                <div className="group-schedule-form">
                  <Form
                    onSubmit={formik.handleSubmit}
                    className="page-form-wrapper activity-model-popup">
                      {renderStep()}
                  </Form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
CreateGroupScheduleModel.propTypes = {
  t: PropTypes.any,
  show: PropTypes.any,
  handleCloseModel: PropTypes.any,
  formik: PropTypes.any,
  editGroupSchedule: PropTypes.any,
  leaveGroup: PropTypes.any,
  memberList: PropTypes.any,
  isCustomType: PropTypes.any,
  setIsCustomType: PropTypes.any,
  editGroupScheduleDetails: PropTypes.any,
  handleClearModel: PropTypes.any,
  step: PropTypes.any,
  setStep: PropTypes.any,
  isMobile: PropTypes.any,
};
export { CreateGroupScheduleModel };
