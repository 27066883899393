import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial Values of globally declared states in redux
 */
const intialState = {
  temp_setting_get: false,
};

/**
 * Slice for Setting Data
 */
export const tempSettingSlice = createSlice({
  name: 'temp',
  initialState: intialState,
  /**
   * Reducer functions for settings info
   */
  reducers: {
    addTempSetting: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
      state.temp_setting_get = true;
      return state;
    },
    clearTempSetting: () => intialState,
  },
});
export const { addTempSetting, clearTempSetting } = tempSettingSlice.actions;
export const tempSettingData = (state) => state.temp;
export default tempSettingSlice.reducer;
