import { LogLevel } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '36d19883-9c88-4659-a2ba-9c41ff36b630',
    authority: 'https://login.microsoftonline.com/common',
    clientSecret: '6a146513-de40-4eae-acc8-36b6bebca075',
    redirectUri: 'https://synco.social',
    responseType: 'code',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const loginRequest = [
  'openid',
  'profile',
  'email',
  'User.Read',
  'offline_access',
  'Calendars.ReadWrite',
];
export { msalConfig, loginRequest };
