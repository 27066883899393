import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import PlusIcon from 'common/icons/PlusIcon';
import { Link } from 'react-router-dom';
const SyncGroupAccount = ({
  t,
  handleShowModel,
  handleEditGroupScheduleModel,
  editGroupSchedule,
  groupSchedule,
  isMobile,
}) => {
  return (
    <Col xs="12" lg="6" className="mt-40 group-schedule-content-wrapper">
      <Row>
        <div className="text-center sync-calendar-title-wrapper mb-4">
          <h3 className="mb-3">{t('calendar.group_calendar_sync_schedules_title')}</h3>
          <p>{t('calendar.group_calendar_sync_schedules_sub_title')}</p>
        </div>
        <div className="sync-calendar-list mb-40 px-4 px-md-0">
          {groupSchedule.length === 0 ? (
            <h6 className="text-center mt-3">{t('calendar.no_group_schedule_account_found')}</h6>
          ) : (
            groupSchedule.map((calendar) => (
              <div
                key={calendar.group_schedule_id}
                className="sync-calendar-item d-flex align-items-center justify-content-between">
                <div className="sync-calendar-details">
                  <Link to={`/group-calender/${calendar.group_schedule_id}`} className='text-decoration-none'>
                    <h5>{calendar.title}</h5>
                  </Link>
                  <p>{calendar.description}</p>
                </div>
                <div className="sync-calendar-btn-wrapper">
                  {isMobile ? (
                    <>
                      <Link
                        to={`/edit-group-schedules/${calendar.group_schedule_id}`}
                        className="calendar-edit-btn"
                        onClick={() => handleEditGroupScheduleModel(calendar.group_schedule_id)}>
                        {t('calendar.edit_group_schedule_button_label')}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        className="calendar-edit-btn"
                        onClick={() => handleEditGroupScheduleModel(calendar.group_schedule_id)}>
                        {t('calendar.edit_group_schedule_button_label')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="sync-other-calendar-btn d-flex justify-content-center mb-4">
          <Col xs="9" lg="6">
            {isMobile ? (
              <>
                <Link
                  to={'/create-group-schedules'}
                  className="btn btn-primary-without-bg"
                  onClick={handleShowModel}>
                  <PlusIcon width={22} height={22} color={'3583A9'}></PlusIcon>
                  {t('calendar.create_new_group_schedule_button_label')}
                </Link>
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  className="btn btn-primary-without-bg"
                  onClick={handleShowModel}>
                  <PlusIcon width={22} height={22} color={'3583A9'}></PlusIcon>
                  {t('calendar.create_new_group_schedule_button_label')}
                </Button>
              </>
            )}
          </Col>
        </div>
      </Row>
    </Col>
  );
};
SyncGroupAccount.propTypes = {
  t: PropTypes.any,
  handleShowModel: PropTypes.any,
  handleEditGroupScheduleModel: PropTypes.any,
  editGroupSchedule: PropTypes.any,
  groupSchedule: PropTypes.any,
  isMobile: PropTypes.any,
};
export { SyncGroupAccount };
