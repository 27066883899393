import React from 'react';
import PropTypes from 'prop-types';
const GroupScheduleMenuIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0002 21.73C6.63709 21.73 2.27368 17.3666 2.27368 12.0034C2.27368 6.64027 6.63709 2.27686 12.0002 2.27686C17.3634 2.27686 21.7268 6.64027 21.7268 12.0034C21.7268 17.3666 17.3634 21.73 12.0002 21.73ZM12.0002 3.72998C7.44043 3.72998 3.72681 7.4436 3.72681 12.0034C3.72681 16.5632 7.44043 20.2769 12.0002 20.2769C16.5601 20.2769 20.2737 16.5656 20.2737 12.0034C20.2737 7.44126 16.5624 3.72998 12.0002 3.72998Z"
        fill={svgColor}
        stroke={svgColor}
        strokeWidth="0.046875"
      />
      <path
        d="M11.9452 10.2635L11.9451 10.2633C11.8767 9.29886 12.1725 8.41251 12.777 7.76758C13.3816 7.12256 14.2269 6.77515 15.1721 6.77515C16.1101 6.77515 16.9583 7.12997 17.5611 7.77367C18.1638 8.41728 18.467 9.30883 18.3985 10.2643L18.3985 10.2644C18.3393 11.1547 17.9777 11.998 17.3735 12.6546C16.7615 13.3071 15.98 13.6658 15.1721 13.6658C14.3637 13.6658 13.5817 13.3072 12.9702 12.6547C12.3651 11.9983 12.0033 11.1543 11.9452 10.2635ZM10.389 10.973L10.389 10.973C10.2753 12.5464 9.05697 13.8233 7.67911 13.8233C6.29891 13.8233 5.08009 12.5459 4.96827 10.9702C4.91104 10.1643 5.16137 9.42038 5.67308 8.87477C6.18487 8.32908 6.89101 8.03843 7.67864 8.03843C8.4661 8.03843 9.17182 8.33786 9.68044 8.88132C10.189 9.42469 10.4472 10.1738 10.389 10.973ZM19.6793 16.6532C18.5907 18.4478 16.9081 19.8048 14.9233 20.4884C12.9383 21.1722 10.7766 21.1392 8.81354 20.3953C8.75766 20.238 8.75567 20.0196 8.77833 19.7989C8.80137 19.5746 8.84949 19.3515 8.88872 19.1952L8.88872 19.1952C9.22044 17.8688 10.0433 16.7685 11.2689 16.0135C12.357 15.3434 13.7429 14.9741 15.1721 14.9741C16.629 14.9741 17.9774 15.3285 19.073 15.9977C19.428 16.2149 19.605 16.4422 19.6793 16.6532ZM9.78681 15.4813C8.6885 16.376 7.98018 17.4828 7.6475 18.8101C7.56869 19.1121 7.51926 19.4211 7.49991 19.7326L7.44372 19.737C5.854 18.7983 4.59091 17.3943 3.82491 15.7149C3.83333 15.7126 3.84187 15.7103 3.85051 15.7079C4.0336 15.6575 4.26356 15.5943 4.49734 15.4501C5.38518 14.9033 6.56627 14.6019 7.67911 14.6019C8.47353 14.6019 9.11321 14.6977 9.67296 14.8763C9.73266 14.896 9.786 14.9313 9.82742 14.9786C9.86886 15.0259 9.89685 15.0835 9.90847 15.1453C9.9201 15.2071 9.91493 15.2709 9.89351 15.33C9.87211 15.3891 9.83526 15.4413 9.78681 15.4813Z"
        fill={svgColor}
        stroke={svgColor}
        strokeWidth="0.046875"
      />
    </svg>
  );
};
GroupScheduleMenuIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default GroupScheduleMenuIcon;
