import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
const SeoHeader = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="og:title" content={title} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
    </Helmet>
  );
};
SeoHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SeoHeader;
