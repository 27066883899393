import client from 'libs/HttpClient';

class AuthService {
  static signUp(data) {
    return client.post('auth/sign-up', data);
  }
  static updateEmailVerificationStatus(data) {
    return client.post('auth/update-email-verification-status', data);
  }
  static socialSignUp(data) {
    return client.post('auth/social-signup', data);
  }
  static resetPasswordCheckAccountSignupType(data) {
    return client.post('auth/reset-password-check-account-signup-type', data);
  }
  static getUserByEmail(data) {
    return client.post('user/get-user-by-email', data);
  }
  static updateUserProfile(data) {
    return client.post('user/update-user-profile', data);
  }
  static updateSignupType(data) {
    return client.post('user/update-signup-type', data);
  }

  static resendVerificationEmail(data) {
    return client.post('user/resend-verification-email', data);
  }
}

export { AuthService };
