import React from 'react';
import Errorpage from 'assets/images/404.jpg';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from 'assets/images/logo.png';
import { settingData } from 'store/features/settingSlice';
import { SeoHeader } from 'common/components';
import { Container, Row } from 'react-bootstrap';
const PageNotFound = ({ t }) => {
  const getSettingData = useSelector(settingData);
  const brandLogo = getSettingData.website_logo ?? Logo;
  return (
    <>
      <SeoHeader
        title={getSettingData && getSettingData.page_no_found_meta_title}
        description={getSettingData && getSettingData.page_no_found_meta_description}
      />
      <div className="d-flex align-items-center justify-content-center h-100vh p-3">
        <Container>
          <Row className="justify-content-md-center page-form-wrapper align-items-center">
            <div className="content-wrapper cms-page-content-wrapper text-center">
              <Col xs="12" lg="12" className="mb-2">
                <div className="text-center cms-page-brand-logo-wrapper">
                  <Link to="/">
                    <img className="brand-logo-img" src={brandLogo} alt="Synco" />
                  </Link>
                </div>
                <img src={Errorpage} alt="404" className="not-found-image mt-4" />
                <div className="mt-3 mb-3">
                  <h5>{t('page.not_found_text')}</h5>
                </div>
                <Col xs="12" lg="3" className="mb-2 text-center mx-auto">
                  <Link to="/dashboard" className="btn btn-primary btn-primary-with-bg">
                    {t('page.back_to_home_placeholder')}
                  </Link>
                </Col>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

PageNotFound.propTypes = {
  t: PropTypes.func,
};

export default PageNotFound;
