import React from 'react';
import PropTypes from 'prop-types';
const MenuClose = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#fff';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill={svgColor}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 6.8203L17.1802 5.25046L12 10.4306L6.81985 5.25046L5.25001 6.8203L10.4302 12.0005L5.25001 17.1806L6.81985 18.7505L12 13.5703L17.1802 18.7505L18.75 17.1806L13.5699 12.0005L18.75 6.8203Z"
        fill="#EBF3F7"
      />
    </svg>
  );
};
MenuClose.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default MenuClose;
