import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from 'common/components';
import ArrowBack from 'common/icons/ArrowBack';
import Checkmark from 'common/icons/Checkmark';
import TrashIcon from 'common/icons/TrashIcon';
import { EventColors } from 'config';
import moment from 'moment-timezone';
import BackIcon from 'common/icons/BackIcon';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CreateGroupEvent = ({
  t,
  handleEventModelClose,
  formik,
  isFormSubmited,
  deleteEvent,
  setIsFullDay,
  isFullDay,
  isEventEdit,
  eventEditData,
  isMobileActivity,
  group_schedule_id,
  calendarDetails
}) => {
  const handleClear = (fieldName) => {
    formik.setFieldValue(fieldName, '');
    formik.setFieldTouched(fieldName, false);
  };
  const handleTimeValidation = (e, fieldName) => {
    const { value } = e.target;
    
    if (fieldName === 'startTime') {
      if (value < '17:00') {
        return false;
        // errors.startTime = 'Start time must be 17:00 or later';
      }
    }
  
    if (fieldName === 'endTime') {
      if (value > '23:59') {
        return false;
        // errors.endTime = 'End time must be before midnight';
      } 
    }
    return true;
  };
  
  
  return (
    <>
      <div className="form-title-content-wrapper">
        <h3 className="mb-40 d-flex align-items-center">
          {isMobileActivity &&
            <Link className="back-btn-mobile" to={`/group-calender/${group_schedule_id}`}>
              <BackIcon />
            </Link>
          }
          {isEventEdit ? (
            <>{t('page.edit_activity_text')}</>
          ) : (
            <>{t('page.create_activity_text')}</>
          )}
        </h3>
        <Form onSubmit={formik.handleSubmit} className="page-form-wrapper activity-model-popup">
          {/* Title */}
          <Form.Group className="form-group title-field-input">
            <Form.Label>{t('form.title_label')}</Form.Label>
            <Form.Control
              className={
                'form-field ' +
                (formik.touched.title && formik.errors.title
                  ? 'is-invalid'
                  : formik.touched.title && !formik.errors.title
                    ? 'form-field-success'
                    : '')
              }
              type="text"
              name="title"
              placeholder={t('form.title_placeholder')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              autoComplete="off"
              maxLength={150}
            />

            {formik.touched.title && formik.errors.title ? (
              <div className="clear-btn-wrapper">
                <span className="input-group-text is-invalid " onClick={() => handleClear('title')}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
            ) : null}
            <div className="invalid-feedback">
              {formik.touched.title && formik.errors.title ? (
                <span>{t(formik.errors.title)}</span>
              ) : null}
            </div>
          </Form.Group>
          {/* Location */}
          <Form.Group className="form-group location-input">
            <Form.Label>{t('form.location_label')}</Form.Label>
            <Form.Control
              className={
                'form-field ' +
                (formik.touched.location && formik.errors.location
                  ? 'is-invalid'
                  : formik.touched.location && !formik.errors.location
                    ? 'form-field-success'
                    : '')
              }
              type="text"
              name="location"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
              autoComplete="off"
              placeholder={t('form.location_placeholder')}
              maxLength={250}
            />

            {formik.touched.location && formik.errors.location ? (
              <div className="clear-btn-wrapper">
                <span
                  className="input-group-text is-invalid "
                  onClick={() => handleClear('location')}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
            ) : null}
            <div className="invalid-feedback">
              {formik.touched.location && formik.errors.location ? (
                <span>{t(formik.errors.location)}</span>
              ) : null}
            </div>
          </Form.Group>
          {/* Description */}
          <Form.Group className="form-group mt-3 description-div-wrapper">
            <Form.Label>{t('form.description_label')}</Form.Label>
            <Form.Control
              className={
                'form-field ' +
                (formik.touched.description && formik.errors.description
                  ? 'is-invalid'
                  : formik.touched.description && !formik.errors.description
                    ? 'form-field-success'
                    : '')
              }
              as="textarea"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              autoComplete="off"
              placeholder={t('form.description_placeholder')}
            />

            {formik.touched.description && formik.errors.description ? (
              <div className="clear-btn-wrapper">
                <span
                  className="input-group-text is-invalid "
                  onClick={() => handleClear('description')}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
            ) : null}
            <div className="invalid-feedback">
              {formik.touched.description && formik.errors.description ? (
                <span>{t(formik.errors.description)}</span>
              ) : null}
            </div>
          </Form.Group>
          {/* Start and End Date */}
          <Row className="start-end-date-wrapper mt-3">
            <Col lg="12">
              <Form.Label className="date-label group-date-label">{t('form.date_label')}</Form.Label>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group">
                <Form.Label>{t('form.start_date_label')}</Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.startDate && formik.errors.startDate
                      ? 'is-invalid'
                      : formik.touched.startDate && !formik.errors.startDate
                        ? 'form-field-success'
                        : '')
                  }
                  type="date"
                  name="startDate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                  min={new Date().toISOString().split('T')[0]}
                // min={minDate}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="clear-btn-wrapper">
                    <span
                      className="input-group-text is-invalid"
                      onClick={() => handleClear('startDate')}>
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  </div>
                ) : null}
                <div className="invalid-feedback">
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <span>{t(formik.errors.startDate)}</span>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group">
                <Form.Label>{t('form.end_date_label')}</Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.endDate && formik.errors.endDate
                      ? 'is-invalid'
                      : formik.touched.endDate && !formik.errors.endDate
                        ? 'form-field-success'
                        : '')
                  }
                  type="date"
                  name="endDate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                  min={new Date().toISOString().split('T')[0]}
                // min={minDate}
                />
                {formik.touched.endDate && formik.errors.endDate ? (
                  <div className="clear-btn-wrapper">
                    <span
                      className="input-group-text is-invalid"
                      onClick={() => handleClear('endDate')}>
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  </div>
                ) : null}
                <div className="invalid-feedback">
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <span>{t(formik.errors.endDate)}</span>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group mt-3">
            <Form.Check
              type="checkbox"
              label={t('form.full_day_label')}
              name="fullDay"
              onChange={(e) => {
                formik.handleChange(e);
                setIsFullDay(e.target.checked);
                if (e.target.checked) {
                  formik.setFieldValue('startTime', moment().format('HH:mm'));
                  formik.setFieldValue('endTime', moment().add(1, 'hour').format('HH:mm'));
                }
                formik.setFieldValue('fullDay', e.target.checked);
              }}
              onBlur={formik.handleBlur}
              checked={formik.values.fullDay ?? false}
            />
          </Form.Group>
          {/* Start and End Time */}
          {!isFullDay && (
            <Row className="start-end-time-wrapper mt-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label style={{ color: '#666666' }}>{t('form.start_time_label')}</Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    step="900"
                    className={
                      'form-field ' +
                      (formik.touched.startTime && formik.errors.startTime
                        ? 'is-invalid'
                        : formik.touched.startTime && !formik.errors.startTime
                          ? 'form-field-success'
                          : '')
                    }
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.startTime}
                  />
                  {formik.touched.startTime && formik.errors.startTime ? (
                    <div className="clear-btn-wrapper">
                      <span
                        className="input-group-text is-invalid"
                        onClick={() => handleClear('startTime')}>
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  ) : null}
                  <div className="invalid-feedback errorMsg">
                    {formik.touched.startTime && formik.errors.startTime ? (
                      <span>{t(formik.errors.startTime)}</span>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>{t('form.end_time_label')}</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    step="900"
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.endTime}
                    className={
                      'form-field ' +
                      (formik.touched.endTime && formik.errors.endTime
                        ? 'is-invalid'
                        : formik.touched.endTime && !formik.errors.endTime
                          ? 'form-field-success'
                          : '')
                    }
                  />
                  {formik.touched.endTime && formik.errors.endTime ? (
                    <div className="clear-btn-wrapper">
                      <span
                        className="input-group-text is-invalid"
                        onClick={() => handleClear('endTime')}>
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </div>
                  ) : null}
                  <div className="invalid-feedback errorMsg">
                    {formik.touched.endTime && formik.errors.endTime ? (
                      <span>{t(formik.errors.endTime)}</span>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
          )}

          <div className="action-btn-wrapper mt-4">
            <Row>
              <Col xs="12" lg="6">
                <div className="mb-3">
                  {isMobileActivity ? (
                    <Link
                      className="btn btn-primary btn-primary-without-bg candel-btn mobile-cancle-btn" to={`/group-calender/${group_schedule_id}`}>
                      <ArrowBack width={20} height={20} />
                      {t('calendar.cancel_btn_label')}
                    </Link>
                  ) : (
                    <Button
                      className="btn btn-primary btn-primary-without-bg candel-btn"
                      onClick={handleEventModelClose}>
                      <ArrowBack width={20} height={20} />
                      {t('calendar.cancel_btn_label')}
                    </Button>
                  )}

                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="mb-3">
                  <Button
                    type="submit"
                    disabled={isFormSubmited}
                    className="btn btn-primary btn-primary-without-bg save-btn">
                    {isFormSubmited && <ButtonSpinner />}
                    <Checkmark width={20} height={20}></Checkmark>
                    {t('calendar.save_btn_label')}
                  </Button>
                </div>
              </Col>
            </Row>
            {isEventEdit && (
              <Row>
                <Col lg="12">
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-primary btn-primary-without-bg cancel-activity-btn"
                      onClick={() => deleteEvent(eventEditData.group_schedule_event_id)}>
                      <TrashIcon width={18} height={18} color={'fff'}></TrashIcon>
                      {t('form.cancel_activity_label')}
                    </button>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};
CreateGroupEvent.propTypes = {
  t: PropTypes.any,
  handleEventModelClose: PropTypes.any,
  formik: PropTypes.any,
  isFormSubmited: PropTypes.any,
  deleteEvent: PropTypes.any,
  setIsFullDay: PropTypes.any,
  isFullDay: PropTypes.any,
  isEventEdit: PropTypes.any,
  eventEditData: PropTypes.any,
  isMobileActivity: PropTypes.any,
  group_schedule_id: PropTypes.any,
  calendarDetails: PropTypes.any,
};
export { CreateGroupEvent };
