import { React } from 'react';
import PropTypes from 'prop-types';
import { Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { settingData } from 'store/features/settingSlice';
import { Header } from './Header/Header';
const LogoUrlFront = () => {
  /**
   * This will provide  general information from redux store, like logos, and titles
   */
  const getSettingData = useSelector(settingData);

  return (
    <img
      src={getSettingData?.admin_setting_dahsboard_logo}
      alt="Synco"
      width={'100%'}
      className="nav-brand m-2"
    />
  );
};

const UserPrivateLayout = (props) => {
  const { t } = useTranslation();

  let bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.className = 'bg-white login-user-dashboard';

  return (
    <>
      <div className="wrapper">
        <Header t={t} />
        <div id="content" className='auth-user-content'>
          <Container>
            <Row className="justify-content-md-center align-items-center">{props.children}</Row>
          </Container>
        </div>
      </div>
    </>
  );
};

UserPrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export { UserPrivateLayout, LogoUrlFront };
