import React from 'react';
import PropTypes from 'prop-types';
const HomeIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  // const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75009 9.93906V21.0016C3.75009 21.2005 3.82911 21.3912 3.96976 21.5319C4.11041 21.6725 4.30118 21.7516 4.50009 21.7516H9.00009V15.3766C9.00009 15.0782 9.11862 14.792 9.3296 14.5811C9.54058 14.3701 9.82672 14.2516 10.1251 14.2516H13.8751C14.1735 14.2516 14.4596 14.3701 14.6706 14.5811C14.8816 14.792 15.0001 15.0782 15.0001 15.3766V21.7516H19.5001C19.699 21.7516 19.8898 21.6725 20.0304 21.5319C20.1711 21.3912 20.2501 21.2005 20.2501 21.0016V9.93906"
        stroke="#EBF3F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 12.0034L12.5105 2.44092C12.2761 2.19342 11.7281 2.19061 11.4895 2.44092L1.5 12.0034M18.75 8.39405V3.00342H16.5V6.2378"
        stroke="#EBF3F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
HomeIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default HomeIcon;
