import React from 'react';
import PropTypes from 'prop-types';
const MyProfile = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.125 6.75439C15.9412 9.23268 14.0625 11.2544 12 11.2544C9.93748 11.2544 8.05545 9.23315 7.87498 6.75439C7.68748 4.17627 9.5156 2.25439 12 2.25439C14.4844 2.25439 16.3125 4.22314 16.125 6.75439Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.2526C7.92187 14.2526 3.7828 16.5026 3.01687 20.7495C2.92452 21.2614 3.21421 21.7526 3.74999 21.7526H20.25C20.7862 21.7526 21.0759 21.2614 20.9836 20.7495C20.2172 16.5026 16.0781 14.2526 12 14.2526Z"
        stroke={svgColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
MyProfile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default MyProfile;
