import React from 'react';
import PropTypes from 'prop-types';
const CrossCancleIcon = ({ width, height }) => {
  const svgWidth = width ? `${width}px` : '19px';
  const svgHeight = height ? `${height}px` : '18px';
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.22622 6.01694L11.3106 10.1013L10.101 11.311L6.01657 7.22658L6 7.21001L5.98343 7.22658L1.89904 11.311L0.689396 10.1013L4.77378 6.01694L4.79035 6.00037L4.77378 5.98379L0.689396 1.89941L1.89904 0.689762L5.98343 4.77415L6 4.79072L6.01657 4.77415L10.101 0.689762L11.3106 1.89941L7.22622 5.98379L7.20965 6.00037L7.22622 6.01694Z"
        fill="white"
        stroke="white"
        strokeWidth="0.046875"
      />
    </svg>
  );
};
CrossCancleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default CrossCancleIcon;