import React from 'react';
import PropTypes from 'prop-types';
const TrashIcon = ({ width, height, color }) => {
  const svgWidth = width ? `${width}px` : '24px';
  const svgHeight = height ? `${height}px` : '24px';
  const svgColor = color ? `#${color}` : '#1D3946';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 18 22"
      fill={svgColor}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.979 3.50073H12.7501V1.25073C12.7501 1.05182 12.671 0.861055 12.5304 0.720402C12.3897 0.57975 12.199 0.500732 12.0001 0.500732H6.00006C5.80115 0.500732 5.61038 0.57975 5.46973 0.720402C5.32908 0.861055 5.25006 1.05182 5.25006 1.25073V3.50073H0.0211546L6.10352e-05 5.37573H1.54694L2.48865 20.0945C2.51249 20.4751 2.68041 20.8324 2.95827 21.0936C3.23613 21.3548 3.60306 21.5004 3.98444 21.5007H14.0157C14.3968 21.5007 14.7637 21.3555 15.0417 21.0948C15.3197 20.834 15.488 20.4772 15.5124 20.0968L16.4532 5.37573H18.0001L17.979 3.50073ZM5.25006 18.5007L4.82819 6.50073H6.37506L6.79694 18.5007H5.25006ZM9.75006 18.5007H8.25006V6.50073H9.75006V18.5007ZM10.8751 3.50073H7.12506V2.18823C7.12506 2.1385 7.14482 2.09081 7.17998 2.05565C7.21514 2.02049 7.26283 2.00073 7.31256 2.00073H10.6876C10.7373 2.00073 10.785 2.02049 10.8201 2.05565C10.8553 2.09081 10.8751 2.1385 10.8751 2.18823V3.50073ZM12.7501 18.5007H11.2032L11.6251 6.50073H13.1719L12.7501 18.5007Z"
        fill="#666666"
      />
    </svg>
  );
};
TrashIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.any,
};
export default TrashIcon;
