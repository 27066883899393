import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ShareScheduleType } from 'config';
import PropTypes from 'prop-types';
import ArrowBack from 'common/icons/ArrowBack';
import LeaveGroupIcon from 'common/icons/LeaveGroupIcon';
import Checkmark from 'common/icons/Checkmark';
const FormStep2 = ({
  t,
  formik,
  handleClear,
  prevStep,
  editGroupSchedule,
  leaveGroup,
  editGroupScheduleDetails,
}) => {
  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedAccounts = formik.values.accounts.map((account, i) =>
      i === index ? { ...account, shared: !account.shared } : account
    );
    formik.setFieldValue('accounts', updatedAccounts);
  };

  // Handle radio button change
  const handleRadioChange = (index, value) => {
    const updatedAccounts = formik.values.accounts.map((account, i) =>
      i === index ? { ...account, shareType: value } : account
    );
    formik.setFieldValue('accounts', updatedAccounts);
  };
  return (
    <>
      <div className="group-schedule-step mt-4">
        <span>2</span>
        {t('group_schedule.group_schedule_step_2')}
      </div>
      <div className="group-schedule-step-2">
        <Form.Group className="form-group color-wrapper">
          <div
            className={
              'd-flex  align-items-center ' +
              (formik.touched.color && formik.errors.color
                ? 'is-invalid'
                : formik.touched.color && !formik.errors.color
                  ? 'form-field-success'
                  : '')
            }>
            <Form.Label>{t('group_schedule.activity_color_label')}:</Form.Label>
            <Form.Control
              className={
                'color-picker-circle form-field ' +
                (formik.touched.color && formik.errors.color
                  ? 'is-invalid'
                  : formik.touched.color && !formik.errors.color
                    ? 'form-field-success'
                    : '')
              }
              type="color"
              name="color"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.color}
              autoComplete="off"
              maxLength={150}
            />
            {/* <div className="d-flex ml-30">
              {colors.map((colorOption, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  id={`color-${index}`}
                  // label={colorOption.label}
                  style={{
                    backgroundColor: colorOption.value,
                    marginRight: '10px',
                    padding: '5px',
                    borderRadius: '50%',
                    borderColor: formik.values.color === colorOption.value ? colorOption.value : '',
                  }}
                  name="color"
                  value={colorOption.value}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  checked={
                    formik.values.color ? formik.values.color.includes(colorOption.value) : ''
                  }
                  className={`${formik.values.color === colorOption.value ? 'selected' : ''}`}
                />
              ))}
            </div> */}
            {formik.touched.color && formik.errors.color ? (
              <div className="clear-btn-wrapper">
                <span className="input-group-text is-invalid" onClick={() => handleClear('color')}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
            ) : null}
            <div className="invalid-feedback w-100">
              {formik.touched.color && formik.errors.color ? (
                <span>{t(formik.errors.color)}</span>
              ) : null}
            </div>
          </div>


        </Form.Group>

        <div className="share-schedule-list-wrapper mt-3">
          <span className="form-label">{t('group_schedule.share_my_schedules_label')}</span>
          <div className="share-schedule-list-item mt-1">
            {formik.values.accounts.map((account, index) => (
              <div key={index} className="share-schedule-account-details">
                <div className="share-schedule-account">
                  <div className={'share-account-details ' + (account.account_type_id === 1 && 'mb-2') }>
                    <label>{account.account_type} {account.account_type_id === 1 &&
                      (<> <span>(Main Calender)</span>
                      </>)}</label>
                    {account.account_type_id !== 1 &&
                      (
                        <>
                          <p>{account.email}</p>
                        </>
                      )
                    }

                  </div>
                  <div className="shared-checkbox-wrapper">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        label="Shared"
                        name={`accounts[${index}].shared`}
                        checked={account.shared}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="shared-account">
                  {ShareScheduleType.map((type, i) => (
                    <Form.Check
                      key={i}
                      type="radio"
                      id={`share-schedule-${index}-${i}`}
                      label={type.label}
                      name={`accounts[${index}].shareType`}
                      value={type.value}
                      disabled={!account.shared}
                      onChange={(e) => handleRadioChange(index, e.target.value)}
                      checked={account.shareType === type.value}
                      className={`${account.shareType === type.value ? 'selected' : ''} `}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        {!editGroupSchedule && (
          <Col xs="12" lg="12">
            <Row className="btn-wrapper mt-4">
              <Col xs="6" lg="6">
                <Button
                  onClick={prevStep}
                  className="btn btn-primary btn-primary-without-bg cancel-btn">
                  <ArrowBack width={18} height={18}></ArrowBack>previous
                </Button>
              </Col>
              <Col xs="6" lg="6">
                <Button type="submit" className="btn btn-primary btn-primary-without-bg submit-btn d-flex align-items-center">
                  <Checkmark></Checkmark>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </div>
      {editGroupSchedule && (
          <Col xs="12" lg="12" className='edit-gs-btns'>
            <Row className="btn-wrapper mt-4 ">
              <Col xs="6" lg="6">
                <Button
                  onClick={() => leaveGroup(editGroupScheduleDetails?.group_schedule_id)}
                  className="btn btn-primary btn-primary-without-bg cancel-btn">
                  <LeaveGroupIcon width={18} height={18}></LeaveGroupIcon>Leave group
                </Button>
              </Col>
              <Col xs="6" lg="6">
                <Button type="submit" className="btn btn-primary btn-primary-without-bg submit-btn"><Checkmark></Checkmark>Save</Button>
              </Col>
            </Row>
          </Col>
        )}
    </>
  );
};
FormStep2.propTypes = {
  t: PropTypes.any,
  formik: PropTypes.any,
  handleClear: PropTypes.any,
  prevStep: PropTypes.any,
  editGroupSchedule: PropTypes.any,
  leaveGroup: PropTypes.any,
  editGroupScheduleDetails: PropTypes.any,
};
export { FormStep2 };
